<!-- 1 每月租金 -->
<div style="z-index: 999999;background: rgba(0,0,0,0.4);" class="modal fade" bsModal #monthRent="bs-modal"
     [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>每月租金</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="detailDialog.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table w-100 ">
          <thead>
          <tr>
            <th>第几个月</th>
            <th>时间段</th>
            <th>租金</th>
          </tr>
          </thead>
          <tbody class="over12">
          <tr *ngFor="let item of leaseCustomRentList; let i = index">
            <td>{{i + 1}}</td>
            <td>{{item?.start_time |date: 'yyyy.MM.dd'}} —— {{item?.end_time |date:'yyyy.MM.dd'}}</td>
            <td>{{item?.custom_rent}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<!-- 其他费用 -->
<!--<div style="z-index: 999999;background: rgba(0,0,0,0.4);" class="modal fade" bsModal #otherExpense="bs-modal"-->
<!--     [config]="{backdrop: 'static'}"-->
<!--     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">-->
<!--  <div class="modal-dialog modal-primary modal-sm">-->
<!--    <div class="modal-content">-->
<!--      <div class="modal-header">-->
<!--        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>其他费用</h4>-->
<!--        <button type="button" class="close pull-right" aria-label="Close" (click)="expenseDialog.hide()">-->
<!--          <span><i class="icon icon-close" aria-hidden="true"></i></span>-->
<!--        </button>-->
<!--      </div>-->
<!--      <div class="modal-body">-->
<!--        <table class="table w-100 ">-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>收取频率</th>-->
<!--            <th>金额</th>-->
<!--            <th>费用类型</th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody class="over12">-->
<!--          <tr *ngFor="let item of leaseExpenseList">-->
<!--            <td>{{item?.gather_type | gatherTypePipe}}</td>-->
<!--            <td>{{item?.expense_value}}</td>-->
<!--            <td>{{item?.expense_name}}</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--        <div *ngIf="!leaseExpenseList">-->
<!--          无其它费用-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<!--           3        收款dialog      开始       -->
<!-- 3.1 有合同收款 -->
<div class="modal fade" bsModal #gatherBill="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg money-max-width modal-top-sm">
    <div class="modal-content">
      <div class="modal-header money-div">
        <img class="money-img" src="../../../../assets/img/money/u7947.png">
        <h4 class="modal-title pull-left money-title">
          <ng-container *ngIf="expenseType==='otherExpense'&&!showTips">创建账单</ng-container>
          <ng-container *ngIf="expenseType != 'otherExpense'">收银台</ng-container>
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeGatherDialog(gatherBill)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body money-body">

        <!-- 房间筛选 -->
        <div class="mb10">
          <div class="room-div2 mt-2" *ngIf="hasContact && (expenseType != 'otherExpense' || expenseType == 'otherExpense'&& payerType == 'SUBJECT')">
            <p style="margin-bottom:5px;">收银房间</p>
            <input class="w-100 room-input" type="text" (click)="showRoomListDiv()"
                   [ngModel]="selectRoomNames.join('，')" readonly="readonly"/>
          </div>
        </div>
        <div class="room_list mb20" [style.display]="showRoomList ? 'block' : 'none'"
             tabindex="-1" (mouseleave)="hideRoomListDiv()">
          <span class="money-out"></span>
          <ng-container *ngIf="roomInfoDtos && roomInfoDtos.length > 1">
            <div class="room-div" *ngFor="let item of roomInfoDtos; let i=index" [style.width]="i == 0? '100%' : ''">
              <input type="checkbox" [value]="item.room_id+';'+item.room_name" (click)="chooseRoom($event)"
                     [checked]="(((item.room_id > 0 && (roomIds.indexOf(item.room_id) >= 0))
                      || (item.room_id == 0 && roomIds.length == roomInfoDtos.length)))">
              <label>{{item.room_name}}</label>
            </div>
          </ng-container>
          <div style="clear:both;"></div>
        </div>

        <!-- 账单类型 -->
       <div class="col-12">
         <div class="row" *ngIf="expenseType ==='renterExpense'">
           <div class="col-12 col-md-3 mb10" *ngIf="hasContact">
             <p class="text-danger">付款方</p>
             <div class="d-inline-block ml-2">
               <input type="radio" name="payerType" [value]="'SUBJECT'" (click)="changePayerType('SUBJECT')" [checked]="payerType == 'SUBJECT'">签约人
               <input type="radio" name="payerType" [value]="'RENTER'" (click)="changePayerType('RENTER')" [checked]="payerType == 'RENTER'">住客
             </div>
           </div>
           <div class="col-12 col-md-3 mb10" *ngIf="hasContact">
             <div *ngIf="payerType == 'SUBJECT'">
               <p>账单类型：</p>
               <select class="w-100" (change)="selectBillingType($event)" [(ngModel)]="selectBillType">
                 <option value=0>全部</option>
                 <option *ngFor="let item of billTypeList" [value]="item.bill_type_id">{{item.bill_type_name}}</option>
               </select>
             </div>
             <div *ngIf="payerType == 'RENTER'">
               <p>账单类型：</p>
               <select class="w-100" (change)="selectBillingType($event)" [(ngModel)]="selectBillType">
                 <option value=0>全部</option>
                 <option *ngFor="let item of renterBillTypeList" [value]="item.bill_type_id">{{item.bill_type_name}}</option>
               </select>
             </div>
           </div>

          <!-- 创建账单 -->
           <div class="col-12 col-md-6 mb10" *ngIf="hasContact">
             <div class="row">
               <div class="col-6 col-md-7 text-danger money-text1">没有账单？</div>
               <div class="col-6 col-md-4 ">
                  <div class="b-blues pointer money-btn" (click)="changeGatherType('otherExpense')">创建账单
              </div>
               </div>
             </div>
           </div>
         </div>
       </div>

        <!--  创建账单-弹窗  -->
        <div class="partbox1" *ngIf="hasContact" style="margin-left: 20px;margin-right: 20px;">
          <div class="row" *ngIf="expenseType==='otherExpense'&&!showTips">
            <div class="col-12 col-md-6">
              <label style="margin-top: 24px;">创建新账单并收款：</label>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
              </div>
            </div>
          </div>
          <div class="row" *ngIf="expenseType==='otherExpense'&&!showTips&&payerType !== 'RENTER'">
            <div class="col-12 col-md-3 mb-2">
              <label>账单类型</label>
              <ng-container *ngIf="payerType == 'SUBJECT'">
                <select class="w-100" [(ngModel)]="otherBillType" (change)="changeBillType()">
                  <option value="0" disabled>请选择类型</option>
                  <option value="WATER">水费</option>
                  <option value="ELECTRICITY">电费</option>
                  <option value="PROPERTYMANAGEMENTFEE">物业费</option>
                  <option value="BROADBANDFEE">宽带费</option>
                  <option value="PARKING">停车费</option>
                  <option value="OTHER">其他费用</option>
                  <option value="HEATING">暖气费</option>
                  <option value="SERVICE">服务费</option>
                </select>
              </ng-container>
            </div>

            <div class="col-12 col-md-3 mb-2">
              <label>收款金额</label>
              <input type="text" class="w-100" placeholder="请输入金额" (blur)="renterOrderAmountExpense()"
                     (keyup)="format_input_num($event)"
                     [(ngModel)]="otherPayAmount">
            </div>

            <div class="col-12 col-md-6 mb-2" *ngIf="expenseType==='otherExpense'&&!showTips">
              <label>费用所属周期</label>
              <div>
                <div class="d-inline-block" style="width:140px">
                  <my-date-picker (inputFocusBlur)="createPreviewList()" [(ngModel)]="startTime_obj"
                                  (dateChanged)="timeChange($event)" [options]="myDatePickerOptions"
                                  placeholder="年/月/日">
                  </my-date-picker>
                </div>
                <span class="ml-1 mr-1 valign-top">-</span>
                <div class="d-inline-block" style="width: 140px">
                  <my-date-picker (inputFocusBlur)="createPreviewList()" [(ngModel)]="endTime_obj"
                                  (dateChanged)="timeChange($event)" [options]="myDatePickerOptions"
                                  placeholder="年/月/日">
                  </my-date-picker>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-2" >
              <label>应收日期</label>
              <div>
                <div class="d-inline-block" style="width:140px">
                  <my-date-picker (inputFocusBlur)="createPreviewList()" [(ngModel)]="planPayTimeObj"
                                  (dateChanged)="timeChange($event)" [options]="myDatePickerOptions"
                                  placeholder="年/月/日">
                  </my-date-picker>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4 mb-2" *ngIf="expenseType==='otherExpense'&&!showTips">
              <label>备注</label>
              <input type="text" class="w-100" placeholder="请填写收款备注信息" [(ngModel)]="otherRemark">
            </div>

            <div class="col-12 col-md-3 mb-2" *ngIf="expenseType==='otherExpense'&&!showTips && isShow">
              <label>停车类型</label>
              <select class="w-100" [(ngModel)]="parkingType">
                <option value="0" disabled>请选择类型</option>
                <option value="TEMPORARY">临停</option>
                <option value="MONTHLY_CARD">月卡</option>
              </select>
              <span style="color: red" *ngIf="parkingType == '0' && isPakringTypeError">请选择停车类型</span>
            </div>

            <div class="col-12 col-md-3 mb-2" *ngIf="expenseType==='otherExpense'&&!showTips && isShow">
              <label>车牌号</label>
              <input type="text" class="w-100" [(ngModel)]="plateNumber" (blur)="checkPlateNumber()">
              <span style="color: red" *ngIf="plateNumber == '' && isPlateNumberError">请输入车牌号</span>
              <span style="color: red" *ngIf="isPlateLengthError">车牌号最多可输入8位字符</span>
            </div>
          </div>
          <div class="row" *ngIf="expenseType==='otherExpense'&&!showTips&&payerType === 'RENTER'">
            <div class="col-12 col-md-3 mb-2" *ngIf="payerType == 'RENTER'">
              <label>选择房间</label>
              <select class="w-100" (change)="selectRenterRoomInfo($event)" [(ngModel)]="selectRenterRoom">
                <option value=0 disabled>请选择房间</option>
                <option *ngFor="let item of renterRoomInfos" [value]="item.room_id">{{item.room_name}}</option>
              </select>
            </div>
            <div class="col-12 col-md-3 mb-2" *ngIf="payerType == 'RENTER'">
              <label>选择住客</label>
              <select class="w-100" (change)="selectRenterInfo($event)" [(ngModel)]="selectRenterId">
                <option value=0 disabled>请选择住客</option>
                <option *ngFor="let item of renterInfoList" [value]="item.info_id">{{item.name}}</option>
              </select>
            </div>
            <div class="col-12 col-md-3 mb-2">
              <label>账单类型</label>
              <ng-container *ngIf="payerType == 'RENTER'">
                <select class="w-100" (change)="changeRenterBillType($event)" [(ngModel)]="renterBillType">
                  <option value="" disabled>请选择类型</option>
                  <option value="RENTER_COMPENSATION">住客赔偿金</option>
                  <option value="RENTER_DEPOSIT">住客押金</option>
                </select>
              </ng-container>
            </div>

            <div class="col-12 col-md-3 mb-2">
              <label>收款金额</label>
              <input type="text" class="w-100" placeholder="请输入金额" (blur)="renterOrderAmountExpense()"
                     (keyup)="format_input_num($event)"
                     [(ngModel)]="otherPayAmount">
            </div>

            <div class="col-12 col-md-6 mb-2" *ngIf="expenseType==='otherExpense'&&!showTips&&payerType !== 'RENTER'">
              <label>费用所属周期</label>
              <div>
                <div class="d-inline-block" style="width:140px">
                  <my-date-picker [(ngModel)]="startTime_obj"
                                  (dateChanged)="timeChange($event)" [options]="myDatePickerOptions"
                                  placeholder="年/月/日">
                  </my-date-picker>
                </div>
                <span class="ml-1 mr-1 valign-top">-</span>
                <div class="d-inline-block" style="width: 140px">
                  <my-date-picker [(ngModel)]="endTime_obj"
                                  (dateChanged)="timeChange($event)" [options]="myDatePickerOptions"
                                  placeholder="年/月/日">
                  </my-date-picker>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2" *ngIf="expenseType==='otherExpense'&&!showTips&&payerType === 'RENTER'">
              <label>费用所属周期</label>
              <div>
                <div class="d-inline-block" style="width:140px">
                  <my-date-picker [(ngModel)]="startTime_obj"
                                  (inputFieldChanged)="timeChange($event)" [options]="startDatePickerOptions"
                                  placeholder="年/月/日">
                  </my-date-picker>
                </div>
                <span class="ml-1 mr-1 valign-top">-</span>
                <div class="d-inline-block" style="width: 140px">
                  <my-date-picker [(ngModel)]="endTime_obj"
                                   [options]="endDatePickerOptions"
                                  placeholder="年/月/日">
                  </my-date-picker>
                </div>
              </div>
            </div>


            <div class="col-12 col-md-3 mb-2" *ngIf="expenseType==='otherExpense'&&!showTips">
              <label>备注</label>
              <input type="text" class="w-100" placeholder="请填写收款备注信息" [(ngModel)]="renterBillRemark">
            </div>
            <div class="col-12 col-md-3 mb-2" *ngIf="expenseType==='otherExpense'&&!showTips">
              <div style="margin-bottom: 4px">&nbsp;</div>
              <button class="btn btn-primary" (click)="createRenterPreviewBill()">添加</button>
            </div>
          </div>
          <hr class="mt-0"/>
          <ng-container
            *ngIf="expenseType==='otherExpense'&&!showTips && billPreviewList != null && billPreviewList.length > 0 && payerType !== 'RENTER'">
            <!--    账单预览     -->
            <div class="part4">
              <div class="mb-2">
                <span>账单预览:</span>
              </div>

              <div class="table-box" style="max-height:364px;">

                <table class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>房间号</th>
                    <th>账单类型</th>
                    <th>账单周期</th>
                    <th>账单金额</th>
                    <!--<th>状态</th>-->
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of billPreviewList">
                    <td>{{item.room_name}}</td>
                    <td>{{item.bill_type_name}}</td>
                    <td>{{item.startTime_obj | date:"yyyy-MM-dd" }}-{{item.endTime_obj | date:"yyyy-MM-dd"}}
                    </td>
                    <td>{{item.bill_amount}}</td>
                    <!--<td [ngClass]="{'red':item.overdue_amount}">{{item.bill_status_name}}</td>-->
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr/>
          </ng-container>
          <ng-container
            *ngIf="expenseType==='otherExpense'&&!showTips && renterBillPreviewList != null && renterBillPreviewList.length > 0 && payerType === 'RENTER'">
            <!--    账单预览     -->
            <div class="part4">
              <div class="mb-2">
                <span>账单预览:</span>
              </div>

              <div class="table-box" style="max-height:364px;">

                <table class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>房间号</th>
                    <th>住客姓名</th>
                    <th>账单类型</th>
                    <th>账单周期</th>
                    <th>账单金额</th>
                    <th>备注</th>
                    <th>操作</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of renterBillPreviewList;let i = index">
                    <td>{{item.room_name}}</td>
                    <td>{{item.renter_name}}</td>
                    <td>{{item.bill_type_name}}</td>
                    <td>{{item.startTime_obj | date:"yyyy.MM.dd" }}-{{item.endTime_obj | date:"yyyy.MM.dd"}}
                    </td>
                    <td>{{item.bill_amount}}</td>
                    <td>{{item.remark}}</td>
                    <td>
                      <label class="text-blue pointer mb-0" (click)="deleteRenterBillPreview(item, i)">删除</label>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr/>
          </ng-container>

          <div class="money-list row mb-3" *ngIf="expenseType==='otherExpense'&&!showTips">
            <div class="col-lg-12 text-center">
              <button class="btn btn-primary" (click)="createBill()">确认并创建</button>
              <button class="btn btn-secondary ml-4" (click)="changeGatherType('renterExpense')">返回收银台</button>
            </div>
          </div>

          <!--    房费     -->
          <div class="part4" *ngIf="expenseType ==='renterExpense'&&!showTips">
            <div class="mb-2">
              <span>需收款账单如下</span>
            </div>

            <div class="table-box" style="max-height:364px;">

              <!--签约人账单-->
              <table *ngIf="payerType !== 'RENTER'" class="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>
                    <input type="checkbox"
                           [checked]="checkedBillIds.length >0 && checkedBillIds.length == billList.length"
                           (click)="checkAll($event)">
                  </th>
                  <th>房间号</th>
                  <th *ngIf="payerType === 'RENTER'">住客姓名</th>
                  <th>账单类型</th>
                  <th>账单周期</th>
                  <th>应付时间</th>
                  <th>账单金额</th>
                  <th>剩余未付</th>
                  <!--<th>状态</th>-->
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of billList">
                  <td>
                    <input *ngIf="!item.in_audit"
                           type="checkbox" [checked]="(checkedBillIds.indexOf(item.bill_id)>=0)"
                           (click)="checkCurrentBill($event,item.bill_id,item.waiting_pay_amount,item.bill_type_name,null,null)">
                  </td>
                  <td [ngClass]="{'red':item.overdue_amount}">{{item.room_name}}</td>
                  <td *ngIf="payerType === 'RENTER'">{{item.pay_user}}</td>
                  <td [ngClass]="{'red':item.overdue_amount}">{{item.bill_type_name}}</td>
                  <td [ngClass]="{'red':item.overdue_amount}">{{item.bill_start_time |
                    date:'yyyy-MM-dd'}}-{{item.bill_end_time | date:'yyyy-MM-dd'}}
                  </td>
                  <td [ngClass]="{'red':item.overdue_amount}">{{item.plan_pay_time |date: 'yyyy-MM-dd HH:mm:ss'}}</td>
                  <td [ngClass]="{'red':item.overdue_amount}">{{item.bill_amount}}</td>
                  <td [ngClass]="{'red':true}">{{item.waiting_pay_amount}}</td>
                  <!--<td [ngClass]="{'red':item.overdue_amount}">{{item.bill_status_name}}</td>-->
                </tr>
                </tbody>
              </table>
              <!--住客账单-->
              <table *ngIf="payerType === 'RENTER'" class="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>
                    <input type="checkbox"
                           [checked]="checkedCombineIds.length >0 && checkedCombineIds.length == billList.length"
                           (click)="checkAll($event)">
                  </th>
                  <th>房间号</th>
                  <th *ngIf="payerType === 'RENTER'">住客姓名</th>
                  <th>账单类型</th>
                  <th>账单周期</th>
                  <th>应付时间</th>
                  <th>账单金额</th>
                  <th>剩余未付</th>
                  <!--<th>状态</th>-->
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of billList">
                  <td>
                    <input *ngIf="!item.in_audit"
                           type="checkbox" [checked]="(checkedCombineIds.indexOf(item.combine_id)>=0)"
                           (click)="checkCurrentBill($event,item.bill_id,item.waiting_pay_amount,item.bill_type_name, item.renter_went_dutch_bill_id, item.combine_id)">
                  </td>
                  <td [ngClass]="{'red':item.overdue_amount}">{{item.room_name}}</td>
                  <td *ngIf="payerType === 'RENTER'">{{item.pay_user}}</td>
                  <td [ngClass]="{'red':item.overdue_amount}">{{item.bill_type_name}}</td>
                  <td [ngClass]="{'red':item.overdue_amount}">{{item.bill_start_time |
                    date:'yyyy-MM-dd'}}-{{item.bill_end_time | date:'yyyy-MM-dd'}}
                  </td>
                  <td [ngClass]="{'red':item.overdue_amount}">{{item.plan_pay_time |date: 'yyyy-MM-dd HH:mm:ss'}}</td>
                  <td [ngClass]="{'red':item.overdue_amount}">{{item.bill_amount}}</td>
                  <td [ngClass]="{'red':true}">{{item.waiting_pay_amount}}</td>
                  <!--<td [ngClass]="{'red':item.overdue_amount}">{{item.bill_status_name}}</td>-->
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-2 mb-2">
              <span>已选{{ckeckedNum}}个账单，合计<span class="text-danger big">
                  {{confirmPaymentRequest?.payment_total_amount != null ? confirmPaymentRequest?.payment_total_amount : '0'}}</span>元</span>
              <span *ngIf="ckeckedOverdueNum > 0" style="margin-left: 60px;">有
                <span class="text-danger big">{{ckeckedOverdueNum}}</span>
                个账单发生逾期，滞纳金约
                <span class="text-danger big">
                  {{confirmPaymentRequest?.checked_overdue_amount != null ? confirmPaymentRequest?.checked_overdue_amount : '0'}}
                </span>
                 元，完成逾期账单收款后生成滞纳金账单
              </span>
            </div>

          </div>

        </div>

        <!--  预定金抵扣  -->
        <ng-container
          *ngIf="paymentInfoDTO && vaildAmount(paymentInfoDTO?.book_amount) && expenseType ==='renterExpense' && payerType !== 'RENTER'">
          <div class=" mb-1 hei32 ml20">
            <input id="bookCheckBox" type="checkbox"
                   style="vertical-align:middle"
                   (click)="checkBooking($event)"
                   [disabled]="!canBookAmountUseFlag ? true : null"
                   [checked]="selectBookingAmount">
            <label class="form-check-label">
              使用预定金抵扣，
              <span class="text-danger"
                    *ngIf="paymentInfoDTO && vaildAmount(paymentInfoDTO?.book_amount)">预定金{{paymentInfoDTO?.book_amount != null ?
                paymentInfoDTO?.book_amount : '0'}}元</span>

            </label>
            <!--<p class="mb-3 text-center"-->
            <!--*ngIf="selectBookingAmount && expenseType==='otherExpense' && confirmPaymentRequest.actual_payment_amount">-->
            <!--预定金抵扣后还需支付：<span class="text-danger">{{confirmPaymentRequest.actual_payment_amount}}元</span>-->
            <!--</p>-->
          </div>
        </ng-container>

        <!--  优惠券抵扣  -->
        <div class="hei32 ml20" *ngIf="expenseType ==='renterExpense' && payerType !== 'RENTER'">
          <input type="checkbox" style="vertical-align:middle" (change)="changeSelectCouponInput($event)"
                 [checked]="selectCouponInput">
          <label class="form-check-label">
            使用优惠券抵扣
          </label>
          <ng-container *ngIf="selectCouponInput">
            <input class="mr10 ml-1" style="margin-left:10px;" type="text" [(ngModel)]="coupon_number"
                   placeholder="请输入券号" (keyup)="checkIsNull()"/>
            <div class="b-blues pointer" (click)="confirmCouponNumber()"
                 style="display: inline-block;margin-right:10px;">确定
            </div>
            <ng-container *ngIf="coupon_msg">
              (<span style="color: red;">{{coupon_msg}}</span>)
            </ng-container>
          </ng-container>
        </div>

        <!--  应收总计  -->
        <div class="money-total row" *ngIf="expenseType ==='renterExpense'&&!showTips">
          应收总计：
          <div class="money-amount">{{final_amount}}元</div>
          <ng-container *ngIf="!(checkedBillIds.length >0 && final_amount && final_amount == '0') && payerType !== 'RENTER'">
            <div *ngIf="expenseType !=='otherExpense'" class="ml-0 ml-sm-5">
              <span>本次收取</span>
              <input type="text" class="ml-2" style="width: 140px;display: inline-block;" (blur)="renterExpense()"
                     (keyup)="format_input_num($event)"
                     [(ngModel)]="confirmPaymentRequest.actual_payment_amount">元
              <span *ngIf="!selectCouponInput" class="secondary ml-2"
                    style="font-size: 12px;color: rgb(153, 153, 153);">（支持部分收款）</span>
              <span *ngIf="selectCouponInput && coupon_amount == 0" class="secondary ml-2 hint-1"
                    style="font-size: 12px;color: rgb(153, 153, 153);">
                （支持部分收款，收款金额不少于优惠券门槛金额抵扣优惠金额后的金额）
              </span>
              <span *ngIf="selectCouponInput && coupon_amount > 0" class="secondary ml-2 hint-1"
                    style="font-size: 12px;color: rgb(153, 153, 153);">
                （支持部分收款，收款金额不少于优惠券门槛金额抵扣优惠金额后的金额：{{calLeastTotal(coupon_least_use_total, coupon_amount)}}元）
              </span>
            </div>
            <div style="color: red;" *ngIf="actualAmountValidErrorContent">{{actualAmountValidErrorContent}}</div>
          </ng-container>
        </div>

        <ng-container *ngIf="(expenseType !='otherExpense' && (
        !((checkedBillIds.length >0 && final_amount && final_amount == '0') ||
        (checkedBillIds.length >0 && final_amount && turnToNumber(final_amount) > 0 && coupon_amount > 0 && confirmPaymentRequest.actual_payment_amount == '0'
         && calLeastTotal(coupon_least_use_total,coupon_amount) == '0'))))">

          <div class="money-pay-1">
            <span class="">确认金额后，选择收款：</span>
          </div>

          <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 0 && payerType !== 'RENTER'">
          <div class="money-pay-1">
           <div class="">
             <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 1">
               <div class="money-span pointer">
                 <img class="money-img2" src="../../../../assets/img/money/account.png">
                 账户
               </div>
               <span class="ml-2 hint-1 text-sm text-danger valign-bottom">
                 请先选择付款账户
                </span>
             </ng-container>

             <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length == 1">
               <ng-container
                 *ngIf="!compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, queryAccountLeaseResponse.account_money_list[0]);">
                 <div class="money-span pointer">
                   <img class="money-img2" src="../../../../assets/img/money/account.png">
                   账户
                 </div>
               </ng-container>

               <ng-container
                 *ngIf="compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, queryAccountLeaseResponse.account_money_list[0]);">
                 <div class="money-span pointer" (click)="payment('ACCOUNT_MONEY',accountPayment,null, null, queryAccountLeaseResponse.account_money_list[0].account_money_info_id)">
                   <img class="money-img2" src="../../../../assets/img/money/account.png">
                   账户
                 </div>
               </ng-container>

               <span class="ml-2 hint-1 text-sm text-secondary valign-bottom">
                账户余额{{queryAccountLeaseResponse.account_money_list[0].balance}}元
                 <ng-container
                   *ngIf="!compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, queryAccountLeaseResponse.account_money_list[0]);">
               （余额不足）
                 </ng-container>
              </span>
             </ng-container>
           </div>

            <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 1">
            <div class="">
              <div class="text-danger text-sm">该客户存在多个账户，请先选择该次付款的账户</div>
              <ng-container *ngFor="let info of queryAccountLeaseResponse.account_money_list">
                <ng-container
                  *ngIf="compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, info);">
                  <div class="money-span money-span3 active pointer border border-green" (click)="payment('ACCOUNT_MONEY',accountPayment,null, null, info.account_money_info_id)">
                    <div class="text-green1 text-truncate" [title]="info.account_money_name">{{info.account_money_name}}</div>
                    <div class="text-gray1 text-truncate" [title]="info.balance">余额{{info.balance}}元</div>

                    <ng-container *ngIf="account_money_info_id === info.account_money_info_id">
                      <div class="r-flag position-absolute d-inline-block">
                        <i class="fa fa-check"></i>
                        <div class="position-absolute">
                          <div class="position-relative r-flag-angle">
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="!compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, info);">
                  <div class="money-span money-span3 pointer border bg-gray1">
                    <div class="text-gray1 text-truncate">{{info.account_money_name}}</div>
                    <div class="text-gray1 text-truncate">余额{{info.balance}}元</div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            </ng-container>
          </div>
          </ng-container>

          <div class="money-pay mt-2">
            <div class="mb-4 clearfix">
              <span class="money-span pointer" (click)="payment('WEIXIN',aliOrWeixin,'aliOrWeixin')"><img
                class="money-img2" src="../../../../assets/img/money/wx.png">微信</span>
              <span class="money-span pointer" (click)="payment('ALIPAY',aliOrWeixin,'aliOrWeixin')"><img
                class="money-img2" src="../../../../assets/img/money/ali.png">支付宝</span>
              <span class="money-span pointer" (click)="payment('TRANSFER',posPaymnet,null)"><img class="money-img2"
                                                                                                  src="../../../../assets/img/money/zz.png">转账</span>
              <span class="money-span pointer" (click)="payment('CASH',posPaymnet,null)"><img class="money-img2"
                                                                                               src="../../../../assets/img/money/cash.png">现金</span>
              <span class="money-span pointer" (click)="payment('CCBPAY',aliOrWeixin,'aliOrWeixin')"><img
                class="money-img2" src="../../../../assets/img/money/jh.png">龙支付</span>
              <span class="money-span pointer" (click)="payment('POS',posPaymnet,null)"><img class="money-img2"
                                                                                             src="../../../../assets/img/money/pos.png">POS</span>
              <span class="money-span pointer" (click)="payment('CHECK',posPaymnet,null)"><img class="money-img2"
                                                                                               src="../../../../assets/img/money/check.png">支票</span>
            </div>
          </div>
        </ng-container>

        <div class="money-pay" *ngIf="checkedBillIds.length >0 && final_amount && final_amount == '0'">
          <span class="money-span2">确认金额后，选择收款：</span>
          <button type="button" class="btn zeroBtn" (click)="payment(null,null,null)">确认收款</button>
        </div>

        <div class="money-pay" *ngIf="checkedBillIds.length >0 && final_amount && turnToNumber(final_amount) > 0
        && coupon_amount > 0 && confirmPaymentRequest.actual_payment_amount == '0' && calLeastTotal(coupon_least_use_total,coupon_amount) == '0'">
          <span class="money-span2">确认金额后，选择收款：</span>
          <button type="button" class="btn zeroBtn" (click)="payment(null,null,null)">确认收款</button>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- 3.2 无合同收款-->
<div class="modal fade" bsModal #noContractGatherBill="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg money-max-width">
    <div class="modal-content">
      <div class="modal-header money-div">
        <img class="money-img" src="../../../../assets/img/money/u7947.png">
        <h4 class="modal-title pull-left money-title">收银台</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="noContractGatherBill.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body money-body">
        <p class="nolease-p">创建无合同账单并收款：</p>

        <div class="partbox1">
          <div class="row nolease-div">
            <div class="col-12 col-md-3 mb-2" style="margin-left: -15px;">
              <label>账单类型</label>
              <select class="w-100" (change)="clearRemark()" [(ngModel)]="confirmPaymentRequest.bill_type_code">
                <option value=0>全部</option>
                <option *ngFor="let item of billTypeList" [value]="item.bill_type_code">{{item.bill_type_name}}</option>
              </select>
            </div>
            <div class="col-12 col-md-3 mb-2">
              <label>收款金额</label>
              <input type="text" class="w-100" placeholder="请输入收款金额" (blur)="renterExpense()"
                     (keyup)="format_input_num($event)"
                     [(ngModel)]="actualAmount">
            </div>

            <div class="col-12 col-md-4 mb-2">
              <label> &nbsp; &nbsp; </label>
              <input type="text" class="w-100" placeholder="其他项请写具体备注"
                     [disabled]="(confirmPaymentRequest.bill_type_code!=='OTHER' && confirmPaymentRequest.bill_type_code!=='PARKING')"
                     placeholder="其他项请写具体备注" [(ngModel)]="confirmPaymentRequest.remark">
            </div>
          </div>
        </div>

        <div class="money-total">
          应收总计：
          <span
            class="money-amount">{{!confirmPaymentRequest.actual_payment_amount ? 0 : confirmPaymentRequest.actual_payment_amount}}
            元</span>
        </div>

        <div class="money-pay-1">
          <span class="">确认金额后，选择收款：</span>
        </div>

        <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 0">
          <div class="money-pay-1">
            <div class="">
              <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 1">
                <div class="money-span pointer">
                  <img class="money-img2" src="../../../../assets/img/money/account.png">
                  账户
                </div>
                <span class="ml-2 hint-1 text-sm text-danger valign-bottom">
                 请先选择付款账户
                </span>
              </ng-container>

              <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length == 1">
                <ng-container
                  *ngIf="!compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, queryAccountLeaseResponse.account_money_list[0]);">
                  <div class="money-span pointer">
                    <img class="money-img2" src="../../../../assets/img/money/account.png">
                    账户
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, queryAccountLeaseResponse.account_money_list[0]);">
                  <div class="money-span pointer" (click)="payment('ACCOUNT_MONEY',accountPayment,null, null, queryAccountLeaseResponse.account_money_list[0].account_money_info_id)">
                    <img class="money-img2" src="../../../../assets/img/money/account.png">
                    账户
                  </div>
                </ng-container>

                <span class="ml-2 hint-1 text-sm text-secondary valign-bottom">
                账户余额{{queryAccountLeaseResponse.account_money_list[0].balance}}元
                 <ng-container
                   *ngIf="!compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, queryAccountLeaseResponse.account_money_list[0]);">
               （余额不足）
                 </ng-container>
              </span>
              </ng-container>
            </div>

            <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 1">
              <div class="">
                <div class="text-danger text-sm">该客户存在多个账户，请先选择该次付款的账户</div>
                <ng-container *ngFor="let info of queryAccountLeaseResponse.account_money_list">
                  <ng-container
                    *ngIf="compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, info);">
                    <div class="money-span money-span3 active pointer border border-green" (click)="payment('ACCOUNT_MONEY',accountPayment,null, null, info.account_money_info_id)">
                      <div class="text-green1 text-truncate">{{info.account_money_name}}</div>
                      <div class="text-gray1 text-truncate">余额{{info.balance}}元</div>

                      <ng-container *ngIf="account_money_info_id === info.account_money_info_id">
                        <div class="r-flag position-absolute d-inline-block">
                          <i class="fa fa-check"></i>
                          <div class="position-absolute">
                            <div class="position-relative r-flag-angle">
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>

                  <ng-container
                    *ngIf="!compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, info);">
                    <div class="money-span money-span3 pointer border bg-gray1">
                      <div class="text-gray1 text-truncate">{{info.account_money_name}}</div>
                      <div class="text-gray1 text-truncate">余额{{info.balance}}元</div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <div class="money-pay">
          <span class="money-span pointer" (click)="payment('WEIXIN',aliOrWeixin,'aliOrWeixin','LEAVE')"><img
            class="money-img2" src="../../../../assets/img/money/wx.png">微信</span>
          <span class="money-span pointer" (click)="payment('ALIPAY',aliOrWeixin,'aliOrWeixin','LEAVE')"><img
            class="money-img2" src="../../../../assets/img/money/ali.png">支付宝</span>
          <span class="money-span pointer" (click)="payment('TRANSFER',posPaymnet,null,'LEAVE')"><img class="money-img2"
                                                                                                      src="../../../../assets/img/money/zz.png">转账</span>
          <span class="money-span pointer" (click)="payment('CASH',posPaymnet,null,'LEAVE')"><img class="money-img2"
                                                                                                   src="../../../../assets/img/money/cash.png">现金</span>
          <span class="money-span pointer" (click)="payment('CCBPAY',aliOrWeixin,'aliOrWeixin','LEAVE')"><img
            class="money-img2" src="../../../../assets/img/money/jh.png">龙支付</span>
          <span class="money-span pointer" (click)="payment('POS',posPaymnet,null,'LEAVE')"><img class="money-img2"
                                                                                                 src="../../../../assets/img/money/pos.png">POS</span>
          <span class="money-span pointer" (click)="payment('CHECK',posPaymnet,null,'LEAVE')"><img class="money-img2"
                                                                                                   src="../../../../assets/img/money/check.png">支票</span>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- 3.3.1 (在线支付)支付宝/微信收款 -->
<div class="modal fade" bsModal #aliOrWeixin="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeAliOrWei(aliOrWeixin)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <span class="payIcon black" *ngIf="paymentType === 'WEIXIN'">
             <img class="payImg" src="../../../../assets/img/money/wx.png">
              微信
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'ALIPAY'">
             <img class="payImg" src="../../../../assets/img/money/ali.png">
              支付宝
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'CCBPAY'">
             <img class="payImg" src="../../../../assets/img/money/jh.png">
              龙支付
          </span>
          <small class="text-orange">收款金额：<span
            class="money-color">{{confirmPaymentRequest.actual_payment_amount}}</span>元</small>
        </h2>
        <h2 class="red text-center mb20"><small class="text-orange">等待支付中…&nbsp;({{clock}}s)</small>
        </h2>
        <p class="font12 text-center black mb20" *ngIf="confirmPaymentRequest.deductible">
          定金抵扣在支付成功后生效，否则不生效
        </p>
        <p class="font12 black text-center" *ngIf="showContent">
          倒计时结束仍未跳转且客户已支付成功？
          <button class="btn b-blues" style="background-color: white;border: 1px solid  #3366FF;"
                  (click)="showQueryByOrderId(orderQuery,aliOrWeixin)">点击进入
          </button>
        </p>
      </div>
    </div>
  </div>
</div>
<!-- 3.3.2 现金收款 -->
<div class="modal fade" bsModal #cashPayment="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cashPayment.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <span class="payIcon black">
             <img class="payImg" src="../../../../assets/img/money/cash.png">
              现金
          </span>
          <small class="text-orange">收款金额：<span
            class="money-color">{{confirmPaymentRequest.actual_payment_amount}}</span>元</small>
        </h2>
        <p class="font12 text-center black mb20">确认后立即入账且不可更改，是否确认已收？</p>
        <p class="font12 text-center black mb20" *ngIf="confirmPaymentRequest.deductible">
          定金抵扣在支付成功后生效，否则不生效
        </p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20" style="background-color: rgba(242, 90, 99, 1);"
                  (click)="confirm(paymentType,cashPayment,gatherSuccess)">
            确认已收
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="cashPayment.hide()">取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 账户收款 -->
<div class="modal fade" bsModal #accountPayment="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="accountPayment.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <span class="payIcon black">
             <img class="payImg" src="../../../../assets/img/money/cash.png">
              账户
          </span>
          <small class="text-orange">收款金额：<span
            class="money-color">{{confirmPaymentRequest.actual_payment_amount}}</span>元</small>
        </h2>
        <p class="font12 text-center black mb20">确认后立即入账且不可更改，是否确认已收？</p>
        <p class="font12 text-center black mb20" *ngIf="confirmPaymentRequest.deductible">
          定金抵扣在支付成功后生效，否则不生效
        </p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20" style="background-color: rgba(242, 90, 99, 1);"
                  (click)="confirm(paymentType,accountPayment,gatherSuccess)">
            确认已收
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="accountPayment.hide()">取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 3.3.3 POS/转账收款 -->
<div class="modal fade" bsModal #posPaymnet="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="posPaymnet.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <!--<h2 class="red text-center mb20">
          <small class="text-orange">已选择{{paymentTypeName}}付款</small>
          {{confirmPaymentRequest.actual_payment_amount}}元
          <small class="text-orange">请上传凭证附件后保存</small>
        </h2>
        <p class="b-muted font12 text-center mb20" *ngIf="paymentType === 'POS'">POS收款请拍照收银小票</p>
        <p class="b-muted font12 text-center mb20" *ngIf="paymentType === 'TRANSFER'">转账请上传转账凭证</p>
        <p class="b-muted font12 text-center mb20" *ngIf="paymentType === 'CHECK'">支票请拍照</p>
        <div class="text-center mb20">
          <div class="padd15">
            <ul class="img-box">
              <app-new-file-upload [type]="2" [maxSize]="15 * 1024 * 1024" [accept]="'IMG'" [maxLength]="5"
                                   [imgList]="confirmPaymentRequest?.attached_list"
                                   (fileChange)="paymnetFill($event)"></app-new-file-upload>
            </ul>
          </div>
        </div>
        <p class="b-muted font12 text-center mb20"
           *ngIf="paymentType==='CHECK' || paymentType === 'TRANSFER' || paymentType === 'CHECK'">
          最多可上传5个附件，每个15M之内
        </p>

        <p class="font12 text-center black mb40" *ngIf="confirmPaymentRequest.deductible">
          支付成功后，{{confirmPaymentRequest.book_amount}}元定金抵扣才会生效，否则不生效</p>

        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20" (click)="confirm(paymentType,posPaymnet,gatherSuccess)">
            确认已收
          </button>
          <button type="button" class="btn btn-secondary" (click)="posPaymnet.hide()">取消</button>
        </div>-->
        <h2 class="red text-center mb20">
          <span class="payIcon black" *ngIf="paymentType === 'POS'">
             <img class="payImg" src="../../../../assets/img/money/pos.png">
              POS
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'TRANSFER'">
             <img class="payImg" src="../../../../assets/img/money/zz.png">
              转账
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'CHECK'">
             <img class="payImg" src="../../../../assets/img/money/check.png">
              支票
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'CASH'">
             <img class="payImg" src="../../../../assets/img/money/cash.png">
              现金
          </span>
          <small class="text-orange">收款金额：<span
            class="money-color">{{confirmPaymentRequest.actual_payment_amount}}</span>元</small>
        </h2>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;"
           *ngIf="paymentType === 'POS'">请上传pos收银小票：（每张限15M以内，最多5张）</p>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;"
           *ngIf="paymentType === 'TRANSFER'">请上传转账凭证：（每张限15M以内，最多5张）</p>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;"
           *ngIf="paymentType === 'CHECK'">请上传支票照片：（每张限15M以内，最多5张）</p>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;"
           *ngIf="paymentType === 'CASH'">请上传现金收据照片：（每张限15M以内，最多5张）</p>
        <div class="text-center mb20">
          <div class="padd15">
            <ul class="img-box">
              <app-new-file-upload [type]="2" [maxSize]="15 * 1024 * 1024" [accept]="'IMG'" [maxLength]="5"
                                   [imgList]="confirmPaymentRequest?.attached_list"
                                   (fileChange)="paymnetFill($event)"></app-new-file-upload>
            </ul>
          </div>
        </div>
        <p class="font12 text-center black mb40" *ngIf="confirmPaymentRequest.deductible">
          定金抵扣在支付成功后生效，否则不生效
        </p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20" style="background-color: rgba(242, 90, 99, 1);"
                  (click)="confirm(paymentType,posPaymnet,gatherSuccess)">
            确认已收
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="posPaymnet.hide()">取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 3.4 收款成功 -->
<div class="modal fade" bsModal #gatherSuccess="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提醒</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="gatherSuccess.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <!--<h2 class="red text-center mb20">
          <small class="text-orange">已成功收款</small>
          {{confirmPaymentRequest.actual_payment_amount}}元
          <small class="text-orange">此窗口将在{{close}}秒后关闭</small>
        </h2>
        <p class="font12 text-center text-orange mb40" *ngIf="confirmPaymentRequest?.deductible">
          另已成功将{{confirmPaymentRequest?.book_amount}}元定金抵扣账单</p>
        <p class="b-muted font12 text-center mb20">当您看见此界面，代表此款已经入账</p>
        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20" (click)="printPaymentCret()">
            <i class="fa fa-print"></i>打印凭证
          </button>
          <button type="button" class="btn btn-secondary" (click)="gatherSuccess.hide()">关闭</button>
        </div>-->
        <h2 class="red text-center mb20">
          <small class="text-orange" style="font-weight: 400;font-size: 28px;color: #F25A63;">
            收款成功！
            <ng-container *ngIf="paymentType==='CHECK' || paymentType === 'TRANSFER' || paymentType === 'POS' || paymentType === 'CASH'">
              需财务人员核销后到账！
            </ng-container>
          </small>
        </h2>
        <h2 class="red text-center mb10">
          <small class="text-orange">收款金额：</small>
          {{confirmPaymentRequest.actual_payment_amount}}
          <small class="text-orange">元</small>
        </h2>
        <h2 class="red text-center mb20" *ngIf="confirmPaymentRequest?.deductible">
          <small class="text-orange">预订金：</small>{{confirmPaymentRequest?.book_amount}}
          <small class="text-orange">元,已抵扣</small>
        </h2>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button *ngIf="paymentType != null" type="button" class="btn btn-primary mr20"
                  style="background-color: rgba(242, 90, 99, 1);" (click)="printPaymentCret()">
            <i class="fa fa-print"></i>打印凭证
          </button>
          <button *ngIf="paymentType != null" type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="gatherSuccess.hide()">关闭
          </button>
          <button *ngIf="paymentType == null" type="button" class="btn btn-primary"
                  style="background-color: rgba(242, 90, 99, 1);" (click)="gatherSuccess.hide()">关闭
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 3.3.1.2 输入商户订单号： -->
<div class="modal fade" bsModal #orderQuery="bs-modal" style="z-index: 999999;background: rgba(0,0,0,0.4);"
     [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>收款操作</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeOrderQueryDialog(orderQuery)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-left mt-4 mb20 black" style="padding-left: 10%;">请输入用户支付结果页面中显示的商户订单号：
        </p>
        <div class="row mb10">
          <div class="col-md-12" style="padding-left: 13%;">
            <input type="text" class="w-90" [(ngModel)]="orderId">
          </div>
        </div>
        <p class="text-orange font12 text-left mb20" *ngIf="orderRemind" style="padding-left: 10%;">
          未查到该订单号，请确认订单号是否正确</p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20" style="background-color: rgba(242, 90, 99, 1);width: 70px;"
                  (click)="queryByOrderId(orderQuery,gatherSuccess)">
            确认
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="closeOrderQueryDialog(orderQuery)"> 取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 3.3.1.1 账单订单号绑定页面 -->
<div class="modal fade" bsModal #bindOrderBill="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-lg">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="bindOrderBill.hide();checkOrderNo(null)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="red mb10">已到账{{orderNum}}笔相同金额的收款，请选择并确认：</p>
        <div class="table-box">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>请选择</th>
              <th>商户账单号</th>
              <th>支付金额</th>
              <th>支付时间</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of orderDtos">
              <td><input type="radio" name="check_order_no" value="{{item.order_no}}"
                         (click)="checkOrderNo(item.order_no)"></td>
              <td>{{item.order_id}}</td>
              <td>{{item.order_amount}}</td>
              <td>{{item.pay_time |date:'yyyy-MM-dd HH:mm:ss'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <p class="text-orange font12">
          多笔相同金额请核对付款人和商户订单号信息，未找到客户提供的订单号？
          <button class="btn b-blues" style="background-color: white;border: 1px solid  #3366FF;"
                  (click)="showQueryByOrderId(orderQuery,bindOrderBill)">点击进入
          </button>
        </p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button *ngIf="check_order_no" type="button" class="btn btn-primary mr20"
                  style="background-color: rgba(242, 90, 99, 1);width: 70px;"
                  (click)="reletedBillAndOrder(check_order_no,bindOrderBill,gatherSuccess)">
            确认
          </button>
          <button *ngIf="!check_order_no" type="button" class="btn btn-primary mr20"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;">
            确认
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="bindOrderBill.hide();checkOrderNo(null)"> 取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 3.4 收定金 -->
<div class="modal fade" bsModal #bookAmount="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg money-max-width">
    <div class="modal-content">
      <div class="modal-header money-div">
        <img class="money-img" src="../../../../assets/img/money/u7947.png">
        <h4 class="modal-title pull-left money-title">收银台</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bookAmount.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body money-body">
        <div class="money-content">
          <p class="money-sigle-p">正在为预定单<span class="text-primary">{{contractNum}}</span>操作预定金收款</p>
        </div>
        <div class="money-total">
          应收总计：
          <span class="money-amount">
            {{confirmPaymentRequest?.payment_total_amount != null ? confirmPaymentRequest?.payment_total_amount : '0'}}元
          </span>
        </div>

        <div class="money-pay-1">
          <span class="">确认金额后，选择收款：</span>
        </div>

        <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 0">
          <div class="money-pay-1">
            <div class="">
              <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 1">
                <div class="money-span pointer">
                  <img class="money-img2" src="../../../../assets/img/money/account.png">
                  账户
                </div>
                <span class="ml-2 hint-1 text-sm text-danger valign-bottom">
                 请先选择付款账户
                </span>
              </ng-container>

              <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length == 1">
                <ng-container
                  *ngIf="!compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, queryAccountLeaseResponse.account_money_list[0]);">
                  <div class="money-span pointer">
                    <img class="money-img2" src="../../../../assets/img/money/account.png">
                    账户
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, queryAccountLeaseResponse.account_money_list[0]);">
                  <div class="money-span pointer" (click)="payment('ACCOUNT_MONEY',accountPayment,null, null, queryAccountLeaseResponse.account_money_list[0].account_money_info_id)">
                    <img class="money-img2" src="../../../../assets/img/money/account.png">
                    账户
                  </div>
                </ng-container>

                <span class="ml-2 hint-1 text-sm text-secondary valign-bottom">
                账户余额{{queryAccountLeaseResponse.account_money_list[0].balance}}元
                 <ng-container
                   *ngIf="!compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, queryAccountLeaseResponse.account_money_list[0]);">
               （余额不足）
                 </ng-container>
              </span>
              </ng-container>
            </div>

            <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 1">
              <div class="">
                <div class="text-danger text-sm">该客户存在多个账户，请先选择该次付款的账户</div>
                <ng-container *ngFor="let info of queryAccountLeaseResponse.account_money_list">
                  <ng-container
                    *ngIf="compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, info);">
                    <div class="money-span money-span3 active pointer border border-green" (click)="payment('ACCOUNT_MONEY',accountPayment,null, null, info.account_money_info_id)">
                      <div class="text-green1 text-truncate">{{info.account_money_name}}</div>
                      <div class="text-gray1 text-truncate">余额{{info.balance}}元</div>

                      <ng-container *ngIf="account_money_info_id === info.account_money_info_id">
                        <div class="r-flag position-absolute d-inline-block">
                          <i class="fa fa-check"></i>
                          <div class="position-absolute">
                            <div class="position-relative r-flag-angle">
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>

                  <ng-container
                    *ngIf="!compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, info);">
                    <div class="money-span money-span3 pointer border bg-gray1">
                      <div class="text-gray1 text-truncate">{{info.account_money_name}}</div>
                      <div class="text-gray1 text-truncate">余额{{info.balance}}元</div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <div class="money-pay">
          <span class="money-span pointer" (click)="payment('WEIXIN',aliOrWeixin,'aliOrWeixin','LEAVE')"><img
            class="money-img2" src="../../../../assets/img/money/wx.png">微信</span>
          <span class="money-span pointer" (click)="payment('ALIPAY',aliOrWeixin,'aliOrWeixin','LEAVE')"><img
            class="money-img2" src="../../../../assets/img/money/ali.png">支付宝</span>
          <span class="money-span pointer" (click)="payment('TRANSFER',posPaymnet,null,'LEAVE')"><img
            class="money-img2" src="../../../../assets/img/money/zz.png">转账</span>
          <span class="money-span pointer" (click)="payment('CASH',posPaymnet,null,'LEAVE')"><img
            class="money-img2" src="../../../../assets/img/money/cash.png">现金</span>
          <span class="money-span pointer" (click)="payment('POS',posPaymnet,null,'LEAVE')"><img
            class="money-img2" src="../../../../assets/img/money/pos.png">POS</span>
          <span class="money-span pointer" (click)="payment('CHECK',posPaymnet,null,'LEAVE')"><img
            class="money-img2" src="../../../../assets/img/money/check.png">支票</span>
        </div>
      </div>
    </div>
  </div>
</div>
<app-pay-log-detail></app-pay-log-detail>
<!--                   收款dialog        结束     -->

<!-- 3.5 退房收款 -->
<div class="modal fade" bsModal #checkOutModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg money-max-width">
    <div class="modal-content">
      <div class="modal-header money-div">
        <img class="money-img" src="../../../../assets/img/money/u7947.png">
        <h4 class="modal-title pull-left money-title">
          <!--{{confirmPaymentRequest.bussiness_type === 'CHANGEROOM' ? '换房收款' : '退房收款'}}-->
          收银台
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="checkOutModel.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body money-body">
        <div class="money-content">
          <p class="money-sigle-p">正在为合同<span
            class="text-primary">{{contractNum}}</span>操作{{confirmPaymentRequest.bussiness_type === 'CHANGEROOM' ? '换房收款' : '退房收款'}}
          </p>
        </div>
        <div class="money-total">
          应收总计：
          <span class="money-amount">
            {{confirmPaymentRequest?.payment_total_amount != null ? confirmPaymentRequest?.payment_total_amount : '0'}}元
          </span>
        </div>

        <div class="money-pay-1">
          <span class="">确认金额后，选择收款：</span>
        </div>

        <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 0">
          <div class="money-pay-1">
            <div class="">
              <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 1">
                <div class="money-span pointer">
                  <img class="money-img2" src="../../../../assets/img/money/account.png">
                  账户
                </div>
                <span class="ml-2 hint-1 text-sm text-danger valign-bottom">
                 请先选择付款账户
                </span>
              </ng-container>

              <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length == 1">
                <ng-container
                  *ngIf="!compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, queryAccountLeaseResponse.account_money_list[0]);">
                  <div class="money-span pointer">
                    <img class="money-img2" src="../../../../assets/img/money/account.png">
                    账户
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, queryAccountLeaseResponse.account_money_list[0]);">
                  <div class="money-span pointer" (click)="payment('ACCOUNT_MONEY',accountPayment,null, 'LEAVE', queryAccountLeaseResponse.account_money_list[0].account_money_info_id)">
                    <img class="money-img2" src="../../../../assets/img/money/account.png">
                    账户
                  </div>
                </ng-container>

                <span class="ml-2 hint-1 text-sm text-secondary valign-bottom">
                账户余额{{queryAccountLeaseResponse.account_money_list[0].balance}}元
                 <ng-container
                   *ngIf="!compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, queryAccountLeaseResponse.account_money_list[0]);">
               （余额不足）
                 </ng-container>
              </span>
              </ng-container>
            </div>

            <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 1">
              <div class="">
                <div class="text-danger text-sm">该客户存在多个账户，请先选择该次付款的账户</div>
                <ng-container *ngFor="let info of queryAccountLeaseResponse.account_money_list">
                  <ng-container *ngIf="info.special_type === 'DEFAULT_DIC';">

                  <ng-container
                    *ngIf="compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, info);">
                    <div class="money-span money-span3 active pointer border border-green" (click)="payment('ACCOUNT_MONEY',accountPayment,null, 'LEAVE', info.account_money_info_id)">
                      <div class="text-green1 text-truncate">{{info.account_money_name}}</div>
                      <div class="text-gray1 text-truncate">余额{{info.balance}}元</div>

                      <ng-container *ngIf="account_money_info_id === info.account_money_info_id">
                        <div class="r-flag position-absolute d-inline-block">
                          <i class="fa fa-check"></i>
                          <div class="position-absolute">
                            <div class="position-relative r-flag-angle">
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>

                  <ng-container
                    *ngIf="!compareLeaseAccount(this.confirmPaymentRequest.actual_payment_amount, info);">
                    <div class="money-span money-span3 pointer border bg-gray1">
                      <div class="text-gray1 text-truncate">{{info.account_money_name}}</div>
                      <div class="text-gray1 text-truncate">余额{{info.balance}}元</div>
                    </div>
                  </ng-container>

                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <div class="money-pay">
          <span class="money-span pointer" (click)="payment('WEIXIN',aliOrWeixin,'aliOrWeixin','LEAVE')"><img
            class="money-img2" src="../../../../assets/img/money/wx.png">微信</span>
          <span class="money-span pointer" (click)="payment('ALIPAY',aliOrWeixin,'aliOrWeixin','LEAVE')"><img
            class="money-img2" src="../../../../assets/img/money/ali.png">支付宝</span>
          <span class="money-span pointer" (click)="payment('TRANSFER',posPaymnet,null,'LEAVE')"><img class="money-img2"
                                                                                                      src="../../../../assets/img/money/zz.png">转账</span>
          <span class="money-span pointer" (click)="payment('CASH',posPaymnet,null,'LEAVE')"><img class="money-img2"
                                                                                                   src="../../../../assets/img/money/cash.png">现金</span>
          <span class="money-span pointer" (click)="payment('CCBPAY',aliOrWeixin,'aliOrWeixin','LEAVE')"><img
            class="money-img2" src="../../../../assets/img/money/jh.png">龙支付</span>
          <span class="money-span pointer" (click)="payment('POS',posPaymnet,null,'LEAVE')"><img class="money-img2"
                                                                                                 src="../../../../assets/img/money/pos.png">POS</span>
          <span class="money-span pointer" (click)="payment('CHECK',posPaymnet,null,'LEAVE')"><img class="money-img2"
                                                                                                   src="../../../../assets/img/money/check.png">支票</span>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- ==================================================================================================================================== -->


<!-- 4 账单退款选择页面 -->
<div class="modal fade" bsModal #refundBillModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left text-orange">退款申请</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeRefundDialog(refundBillModel)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mb10">
          <div class="col-12 col-md-4 mb10">
            <p>请选择房间：</p>
            <div class="w-100">
              <ngx-select [items]="roomInfoDtos" optionValueField="room_id" optionTextField="room_name"
                          [multiple]="true"
                          (select)="roomSelect('REFUND')" (remove)="roomSelect('REFUND')"
                          [(ngModel)]="roomIds"></ngx-select>
            </div>
          </div>

          <div class="col-12 col-md-4 mb10" *ngIf="hasContact">
            <p>请选择账单类型：</p>
            <select class="w-100" (change)="selectBillingType($event,'REFUND')">
              <option value=0>全部</option>
              <option *ngFor="let item of refundBillTypeList"
                      [value]="item.bill_type_id">{{item.bill_type_name}}</option>
            </select>
          </div>

          <div class="col-12 col-md-4 mb10" *ngIf="hasContact">
            <div class="w-100">
              <span class="text-danger" *ngIf="billList && billList.length === 0">无满足条件的账单</span>
            </div>
          </div>
        </div>
        <div class="partbox1" *ngIf="hasContact">
          <!--    房费     -->
          <div class="part4">
            <div class="mb-2">
              <span class="text-dark ml-3">请选择已支付账单：</span>
            </div>
            <div class="table-box">
              <table class="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>
                    <input type="checkbox" [checked]="checkedBillIds.length>0" (click)="checkRefundBillAll($event)">
                  </th>
                  <th>房间号</th>
                  <th>账单类型</th>
                  <th>账单周期</th>
                  <th>应付时间</th>
                  <th>账单金额</th>
                  <th>已支付金额<br>(含优惠券)</th>
                  <th>可退金额</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of billList">
                  <td>
                    <input type="checkbox" [checked]="(checkedBillIds.indexOf(item.bill_id)>=0)"
                           (click)="checkRefundCurrentBill($event,item.bill_id,item.can_refund,item.bill_type_name)">
                  </td>
                  <td>{{item.room_name}}</td>
                  <td>{{item.bill_type_name}}</td>
                  <td>{{item.bill_start_time |
                    date:'yyyy-MM-dd'}}-{{item.bill_end_time | date:'yyyy-MM-dd'}}
                  </td>
                  <td>{{item.plan_pay_time |date: 'yyyy-MM-dd HH:mm:ss'}}</td>
                  <td>{{item.bill_amount}}</td>
                  <td>{{item.received}}</td>
                  <td>{{item.can_refund}}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="mb-2">
              <span>共选择了{{ckeckedNum}}个账单，退款金额总计<span class="text-danger big">
                  {{refundApplyRequest?.payment_total_amount != null ? refundApplyRequest?.payment_total_amount : '0'}}</span>元</span>
            </div>
          </div>
        </div>

        <div class="row paddingl0">
          <div class="col-12 col-md-12 mb10">
            <p class="hei32 w100px">退款方式：</p>
            <ng-container>
              <div class="form-check form-check-inline hei32 mr20">
                <input (click)="modeOfPaymentSet($event,'TRANSFER')"
                       [checked]="modeOfPayment&&modeOfPayment==='TRANSFER'"
                       class="form-check-input" type="checkbox">
                <label class="form-check-label">转账</label>
              </div>
              <div class="form-check form-check-inline hei32 mr20">
                <input (click)="modeOfPaymentSet($event,'CASE')"
                       [checked]="modeOfPayment&&modeOfPayment==='CASE'"
                       class="form-check-input" type="checkbox">
                <label class="form-check-label">现金</label>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="row paddingl0" *ngIf="modeOfPayment&&modeOfPayment === 'TRANSFER'">
          <div class="col col-lg-4 col-md-4 mt-1">
            <p>收款人姓名：</p>
            <div class="mt-2">
              <input type="text" placeholder="请输入收款人姓名" [(ngModel)]="refundApplyRequest.gather_value"
                     (input)="entryTransferInfo('GATHER_VALUE',$event.target.value)">
            </div>
            <p *ngIf="!refundApplyRequest.gather_value && modeOfPayment === 'TRANSFER'" class="text-danger">收款人姓名必填</p>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1">
            <p>开户行：</p>
            <div class="mt-2">
              <input type="text" placeholder="请输入开户行" [(ngModel)]="refundApplyRequest.bank_name"
                     (input)="entryTransferInfo('BANK_NAME',$event.target.value)">
            </div>
            <p *ngIf="!refundApplyRequest.bank_name && modeOfPayment === 'TRANSFER'" class="text-danger">开户行必填</p>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1">
            <p>银行账号：</p>
            <div class="mt-2">
              <input type="text" placeholder="请输入银行账号" [(ngModel)]="refundApplyRequest.card_number"
                     (input)="entryTransferInfo('CARD_NUMBER',$event.target.value)">
            </div>
            <p *ngIf="!refundApplyRequest.card_number && modeOfPayment === 'TRANSFER'" class="text-danger">银行账号必填</p>
          </div>
        </div>
        <div class="money-list row ">
          <div class="col-lg-12 mt-4 text-center">
            <button type="button" class="btn btn-primary"
                    [attr.disabled]="((modeOfPayment&&modeOfPayment === 'CASE')||
                    (modeOfPayment&&modeOfPayment === 'TRANSFER' && !canRefundNextError)) && ckeckedNum > 0
                     ? null : true"
                    (click)="confirmRefundRequest(refundBillConfirmModel)">
              下一步
            </button>
            <button class="btn btn-default ml-2" (click)="closeRefundDialog(refundBillModel)">取消</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 5 预定金退款页面 -->
<div class="modal fade" bsModal #refundBookingModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left text-orange">预定金退款单填写</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="closeRefundBookingDialog(refundBookingModel)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row paddingl0">
          <div class="col-12 col-md-12 mb10">
            <p class="hei32 w100px">退款方式：</p>
            <ng-container>
              <div class="form-check form-check-inline hei32 mr20">
                <input (click)="modeOfPaymentSet($event,'TRANSFER')"
                       [checked]="modeOfPayment&&modeOfPayment==='TRANSFER'"
                       class="form-check-input" type="checkbox">
                <label class="form-check-label">转账</label>
              </div>
<!--              <div class="form-check form-check-inline hei32 mr20">-->
<!--                <input (click)="modeOfPaymentSet($event,'CASE')"-->
<!--                       [checked]="modeOfPayment&&modeOfPayment==='CASE'"-->
<!--                       class="form-check-input" type="checkbox">-->
<!--                <label class="form-check-label">现金</label>-->
<!--              </div>-->
            </ng-container>
          </div>
        </div>

        <div class="row paddingl0" *ngIf="modeOfPayment&&modeOfPayment === 'TRANSFER'">
          <div class="col col-lg-4 col-md-4 mt-1">
            <p>收款人姓名：</p>
            <div class="mt-2">
              <input type="text" placeholder="请输入收款人姓名" [(ngModel)]="refundApplyRequest.gather_value"
                     (input)="entryTransferInfo('GATHER_VALUE',$event.target.value)">
            </div>
            <p *ngIf="!refundApplyRequest.gather_value && modeOfPayment === 'TRANSFER'" class="text-danger">收款人姓名必填</p>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1">
            <p>开户行：</p>
            <div class="mt-2">
              <input type="text" placeholder="请输入开户行" [(ngModel)]="refundApplyRequest.bank_name"
                     (input)="entryTransferInfo('BANK_NAME',$event.target.value)">
            </div>
            <p *ngIf="!refundApplyRequest.bank_name && modeOfPayment === 'TRANSFER'" class="text-danger">开户行必填</p>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1">
            <p>银行账号：</p>
            <div class="mt-2">
              <input type="text" placeholder="请输入银行账号" [(ngModel)]="refundApplyRequest.card_number"
                     (input)="entryTransferInfo('CARD_NUMBER',$event.target.value)">
            </div>
            <p *ngIf="!refundApplyRequest.card_number && modeOfPayment === 'TRANSFER'" class="text-danger">银行账号必填</p>
          </div>
        </div>
        <div class="money-list row ">
          <div class="col-lg-12 mt-4 text-center">
            <button type="button" class="btn btn-primary"
                    [attr.disabled]="((modeOfPayment&&modeOfPayment === 'CASE')||
                    (modeOfPayment&&modeOfPayment === 'TRANSFER' && !canRefundNextError)) ? null : true"
                    (click)="confirmRefundRequest(refundBookingConfirmModel)">
              确定
            </button>
            <button class="btn btn-default" (click)="closeRefundDialog(refundBookingModel)">取消</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- 4.1 租约退款申请：审批确认 -->
<div class="modal fade" bsModal #refundBillConfirmModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" keyboard="true"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-orange modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left text-orange">退款申请</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="closeRefundDialog(refundBillConfirmModel)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mb-2">
              <span>共选择了{{ckeckedNum}}个账单，退款金额总计<span class="text-danger big">
                  {{refundApplyRequest?.payment_total_amount != null ? refundApplyRequest?.payment_total_amount : '0'}}</span>元</span>
          </div>
          <div class="col-lg-12 mb-1">
            <span>您正在操作退款申请，请进行管理人员短信验证：</span>
          </div>
        </div>
        <div class="row paddingl0">
          <div class="col col-lg-4 col-md-4 mt-1">
            <select class="mt-2 w-100" [(ngModel)]="refundApplyRequest.audit_phone">
              <option value="ALL" disabled>审核人</option>
              <option *ngFor="let man of refundAuditUserList" [value]="man.user_phone">{{man.user_name}}
                ({{man.user_phone}})
              </option>
            </select>
            <div *ngIf="!refundApplyRequest.audit_phone" class="text-danger">审核用户不能为空</div>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1">
            <div class="mt-2">
              <input type="text" placeholder="请输入短信验证码" [(ngModel)]="refundApplyRequest.audit_code"
                     (input)="auditCodeInput($event.target.value)">
            </div>
            <div *ngIf="!refundApplyRequest.audit_code" class="text-danger">验证码不能为空</div>
            <div *ngIf="refundApplyRequest.audit_code && auditCodeError " class="text-danger">短信验证码验证错误</div>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1 pl-0 ml-0">
            <button type="button" class="btn btn-primary pull-left"
                    [attr.disabled]="(refundApplyRequest.audit_phone === 'ALL' || (time>0 && time< 60)) ? true : null "
                    (click)="getAuditCodeWithUser()">
              <span>{{codeBtnStr}}</span>
            </button>
          </div>
        </div>
        <div class="money-list row ">
          <div class="col-lg-12 mt-4 text-center">
            <button class="btn btn-primary"
                    [attr.disabled]="((refundApplyRequest.audit_phone === 'ALL' || !refundApplyRequest.audit_code ||
                 auditCodeError ) ? true : null)"
                    (click)="confirmRefundSubmit();refundBillModel.hide();">提交
            </button>
            <button class="btn btn-default ml-2"
                    (click)="closeRefundConfirmDialog(refundBillConfirmModel)">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 5.1 预定金退款申请：审批确认 -->
<div class="modal fade" bsModal #refundBookingConfirmModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" keyboard="true"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-orange modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left text-orange">预订金退款审核确认</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="closeRefundConfirmDialog(refundBookingConfirmModel)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 mb-1">
            <span>您正在操作预订金{{booking_bill_amount}}元退款申请，请进行管理人员短信验证：</span>
          </div>
        </div>
        <div class="row paddingl0">
          <div class="col col-lg-4 col-md-4 mt-1">
            <select class="mt-2 w-100" [(ngModel)]="refundApplyRequest.audit_phone">
              <option value="ALL" disabled>审核人</option>
              <option *ngFor="let man of refundAuditUserList" [value]="man.user_phone">{{man.user_name}}
                ({{man.user_phone}})
              </option>
            </select>
            <div *ngIf="!refundApplyRequest.audit_phone" class="text-danger">审核用户不能为空</div>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1">
            <div class="mt-2">
              <input type="text" placeholder="请输入短信验证码" [(ngModel)]="refundApplyRequest.audit_code"
                     (input)="auditCodeInput($event.target.value)">
            </div>
            <div *ngIf="!refundApplyRequest.audit_code" class="text-danger">验证码不能为空</div>
            <div *ngIf="refundApplyRequest.audit_code && auditCodeError " class="text-danger">短信验证码验证错误</div>
          </div>
          <div class="col col-lg-4 col-md-4 mt-1 pl-0 ml-0">
            <button type="button" class="btn btn-primary pull-left mt-2"
                    [attr.disabled]="(refundApplyRequest.audit_phone === 'ALL' || (time>0 && time< 60)) ? true : null "
                    (click)="refundBookingAuditCodeWithUser()">
              <span>{{codeBtnStr}}</span>
            </button>
          </div>
        </div>
        <div class="money-list row ">
          <div class="col-lg-12 mt-4 text-center">
            <button class="btn btn-primary"
                    [attr.disabled]="((refundApplyRequest.audit_phone === 'ALL' || !refundApplyRequest.audit_code ||
                 auditCodeError ) ? true : null)"
                    (click)="confirmRefundBookingSubmit();refundBookingModel.hide();">提交
            </button>
            <button class="btn btn-default"
                    (click)="closeRefundConfirmDialog(refundBookingConfirmModel)">取消
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 4.2 退款申请成功提示框-->
<div class="modal fade" bsModal #refundBillConfirmSuccessModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" keyboard="false"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left text-orange">退款申请</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="closeRefundDialog(refundBillConfirmSuccessModel)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row text-center">
          <div class="col-lg-12 col-md-12 pt-10 ">
            退款单申请已提交成功，退款单号：
            <span class="text-danger">{{resultRefundNo}}</span>
          </div>
        </div>
        <div class="money-list row ">
          <div class="col-lg-12 mt-4 text-center">
            <button class="btn btn-primary"
                    (click)="closeRefundDialog(refundBillConfirmSuccessModel)">关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 5.2 预定金退款申请成功提示框-->
<div class="modal fade" bsModal #refundBookingConfirmSuccessModel="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1"
     role="dialog" keyboard="false"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left text-orange">操作结果</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="closeRefundDialog(refundBookingConfirmSuccessModel)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row text-center">
          <div class="col-lg-12 col-md-12 pt-10 ">
            申请退款成功！预定金退款单号：
            <span class="text-danger">{{resultRefundNo}}</span>
          </div>
        </div>
        <div class="money-list row ">
          <div class="col-lg-12 mt-4 text-center">
            <button class="btn btn-primary"
                    (click)="closeRefundDialog(refundBookingConfirmSuccessModel)">关闭
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 6.1 预付费批量充值 -->
<div class="modal fade" bsModal #rechargePrepayModel="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-lg money-max-width">
    <div class="modal-content">
      <div class="modal-header money-div">
        <img class="money-img" src="../../../../assets/img/money/u7947.png">
        <h4 class="modal-title pull-left money-title">收银台</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeRechargePrepayDialog()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body money-body">
        <div class="setbox">您正在为合同号：
          <span class="font-700">{{leaseDetailResponse?.contract_num}}</span>
          <span class="font-700 mr-1 ml-1">{{leaseDetailResponse?.lease_subject_info_dto?.name}}&nbsp;/&nbsp;
            {{leaseDetailResponse?.lease_subject_info_dto?.company_name}}</span>
          操作批量充值预付费：
        </div>
        <div class="setbox" style="padding-top: 0px;">
          <p style="margin-bottom: 10px;">充值明细：</p>
          <table class="table w-100 " style="max-height: 337px;overflow-y: auto;">
            <thead>
            <tr class="grey">
              <th>房间号</th>
              <th>充值金额</th>
              <th>房间号</th>
              <th>充值金额</th>
            </tr>
            </thead>
            <tbody class="over12">

            <tr *ngFor="let item of rechargeRooms; let i = index"
                [style.display]="i*2 < rechargeRooms.length ? '' : 'none'">
              <td class="grey">
                {{rechargeRooms[i * 2]?.room_name}}
              </td>
              <td>
                {{rechargeRooms[i * 2]?.recharge_total}}
              </td>
              <td class="grey">
                <ng-container *ngIf="i*2+1 < rechargeRooms.length">
                  {{rechargeRooms[i * 2 + 1]?.room_name}}
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="i*2+1 < rechargeRooms.length">
                  {{rechargeRooms[i * 2 + 1]?.recharge_total}}
                </ng-container>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="hei32 ml20">
          <input type="checkbox" style="vertical-align:middle" (change)="changeRechargeSelectCouponInput($event)"
                 [checked]="selectCouponInput">
          <label class="form-check-label">
            使用优惠券抵扣
          </label>
          <ng-container *ngIf="selectCouponInput">
            <input class="mr10 ml-1" style="margin-left:10px;" type="text" [(ngModel)]="coupon_number"
                   placeholder="请输入券号"/>
            <div class="b-blues pointer" (click)="confirmRechargeCouponNumber()"
                 style="display: inline-block;margin-right:10px;">确定
            </div>
            <ng-container *ngIf="coupon_msg">
              (<span style="color: red;">{{coupon_msg}}</span>)
            </ng-container>
          </ng-container>
        </div>

        <div class="money-total">
          应收总计：
          <span class="money-amount">{{calRechargeAmount(batchRechargeTotal, coupon_amount)}}元</span>
        </div>

        <div class="money-pay-1">
          <div class="">
            <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 1">
              <div class="money-span pointer">
                <img class="money-img2" src="../../../../assets/img/money/account.png">
                账户
              </div>
              <span class="ml-2 hint-1 text-sm text-danger valign-bottom">
                 请先选择付款账户
                </span>
            </ng-container>

            <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length == 1">
              <ng-container
                *ngIf="!comparePrepayAccount(calRechargeAmount(batchRechargeTotal, coupon_amount), queryAccountLeaseResponse.account_money_list[0]);">
                <div class="money-span pointer">
                  <img class="money-img2" src="../../../../assets/img/money/account.png">
                  账户
                </div>
              </ng-container>

              <ng-container
                *ngIf="comparePrepayAccount(calRechargeAmount(batchRechargeTotal, coupon_amount), queryAccountLeaseResponse.account_money_list[0]);">
                <div class="money-span pointer" (click)="prepayPayment('ACCOUNT_MONEY',prepayAccountPayment,null, queryAccountLeaseResponse.account_money_list[0].account_money_info_id)">
                  <img class="money-img2" src="../../../../assets/img/money/account.png">
                  账户
                </div>
              </ng-container>

              <span class="ml-2 hint-1 text-sm text-secondary valign-bottom">
                账户余额{{queryAccountLeaseResponse.account_money_list[0].balance}}元
                 <ng-container
                   *ngIf="!comparePrepayAccount(calRechargeAmount(batchRechargeTotal, coupon_amount), queryAccountLeaseResponse.account_money_list[0]);">
               （余额不足）
                 </ng-container>
              </span>
            </ng-container>
          </div>

          <ng-container *ngIf="queryAccountLeaseResponse?.account_money_list.length > 1">
            <div class="">
              <div class="text-danger text-sm">该客户存在多个账户，请先选择该次付款的账户</div>
              <ng-container *ngFor="let info of queryAccountLeaseResponse.account_money_list">
                <ng-container
                  *ngIf="comparePrepayAccount(calRechargeAmount(batchRechargeTotal, coupon_amount), info);">
                  <div class="money-span money-span3 active pointer border border-green" (click)="prepayPayment('ACCOUNT_MONEY',prepayAccountPayment,null, info.account_money_info_id)">
                    <div class="text-green1 text-truncate" [title]="info.account_money_name">{{info.account_money_name}}</div>
                    <div class="text-gray1 text-truncate" [title]="info.balance">余额{{info.balance}}元</div>

                    <ng-container *ngIf="account_money_info_id === info.account_money_info_id">
                      <div class="r-flag position-absolute d-inline-block">
                        <i class="fa fa-check"></i>
                        <div class="position-absolute">
                          <div class="position-relative r-flag-angle">
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="!comparePrepayAccount(calRechargeAmount(batchRechargeTotal, coupon_amount), info);">
                  <div class="money-span money-span3 pointer border bg-gray1">
                    <div class="text-gray1 text-truncate">{{info.account_money_name}}</div>
                    <div class="text-gray1 text-truncate">余额{{info.balance}}元</div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </div>

        <div class="money-pay" *ngIf="batchRechargeTotal - coupon_amount > 0">
          <span class="money-span2">确认金额后，选择收款：</span>
          <span class="money-span pointer" (click)="prepayPayment('WEIXIN',prepayaliOrWeixin,'aliOrWeixin')"><img
            class="money-img2" src="../../../../assets/img/money/wx.png">微信</span>
          <span class="money-span pointer" (click)="prepayPayment('ALIPAY',prepayaliOrWeixin,'aliOrWeixin')"><img
            class="money-img2" src="../../../../assets/img/money/ali.png">支付宝</span>
          <span class="money-span pointer" (click)="prepayPayment('TRANSFER',prepayposPaymnet,null)"><img
            class="money-img2" src="../../../../assets/img/money/zz.png">转账</span>
          <span class="money-span pointer" (click)="prepayPayment('CASH',prepayposPaymnet,null)"><img
            class="money-img2" src="../../../../assets/img/money/cash.png">现金</span>
        </div>
        <div class="money-pay" *ngIf="selectCouponInput && batchRechargeTotal - coupon_amount == 0">
          <span class="money-span2">确认金额后，选择收款：</span>
          <button type="button" class="btn zeroBtn" (click)="prepayPayment(null,null,null)">确认收款</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 6.1.1 支付宝/微信收款 -->
<div class="modal fade" bsModal #prepayaliOrWeixin="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left">操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeAliOrWei(prepayaliOrWeixin)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <span class="payIcon black" *ngIf="paymentType === 'WEIXIN'">
             <img class="payImg" src="../../../../assets/img/money/wx.png">
              微信
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'ALIPAY'">
             <img class="payImg" src="../../../../assets/img/money/ali.png">
              支付宝
          </span>
          <small class="text-orange">收款金额：<span class="money-color">{{paymentAmount}}</span>元</small>
        </h2>
        <h2 class="red text-center mb20">
          <small class="text-orange">等待支付中…&nbsp;({{clock}}s)</small>
        </h2>
        <p class="font12 text-center black mb20">
          支付成功后，{{paymentAmount}}元预付账户充值才会生效，否则不生效
        </p>
        <p class="font12 black text-center" *ngIf="showContent">
          倒计时结束仍未跳转且客户已支付成功？
          <button class="btn b-blues" style="background-color: white;border: 1px solid  #3366FF;"
                  (click)="prepayshowQueryByOrderId(prepayorderQuery,prepayaliOrWeixin)">点击进入
          </button>
        </p>
      </div>
    </div>
  </div>
</div>

<!-- 6.1.2 现金收款 -->
<div class="modal fade" bsModal #prepaycashPayment="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="prepaycashPayment.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <span class="payIcon black">
             <img class="payImg" src="../../../../assets/img/money/cash.png">
              现金
          </span>
          <small class="text-orange">收款金额：<span class="money-color">{{paymentAmount}}</span>元</small>
        </h2>
        <p class="font12 text-center black mb20">
          支付成功后，{{paymentAmount}}元预付账户充值才会生效，否则不生效</p>
        <p class="b-muted font12 text-center mb20">确认后立即入账且不可更改，是否确认已收？</p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20" style="background-color: rgba(242, 90, 99, 1);"
                  (click)="confirmPrepayRecharge(paymentType,prepaycashPayment,prepaygatherSuccess)">
            确认已收
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="prepaycashPayment.hide()">取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 6.1.3 POS/转账收款 -->
<div class="modal fade" bsModal #prepayposPaymnet="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left">操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="prepayposPaymnet.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <span class="payIcon black" *ngIf="paymentType === 'POS'">
             <img class="payImg" src="../../../../assets/img/money/pos.png">
              POS
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'TRANSFER'">
             <img class="payImg" src="../../../../assets/img/money/zz.png">
              转账
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'CHECK'">
             <img class="payImg" src="../../../../assets/img/money/check.png">
              支票
          </span>
          <span class="payIcon black" *ngIf="paymentType === 'CASH'">
             <img class="payImg" src="../../../../assets/img/money/cash.png">
              现金
          </span>
          <small class="text-orange">收款金额：<span class="money-color">{{paymentAmount}}</span>元</small>
        </h2>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;"
           *ngIf="paymentType === 'POS'">请上传pos收银小票：（每张限15M以内，最多5张）</p>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;"
           *ngIf="paymentType === 'TRANSFER'">请上传转账凭证：（每张限15M以内，最多5张）</p>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;"
           *ngIf="paymentType === 'CHECK'">请上传支票照片：（每张限15M以内，最多5张）</p>
        <p class="b-muted font12 text-left black" style="padding-left: 15px;margin-top: 40px;margin-bottom: -5px;"
           *ngIf="paymentType === 'CASH'">请上传现金收据照片：（每张限15M以内，最多5张）</p>
        <div class="text-center mb20">
          <div class="padd15">
            <ul class="img-box">
              <app-new-file-upload [type]="2" [maxSize]="15 * 1024 * 1024" [accept]="'IMG'" [maxLength]="5"
                                   [imgList]="prepayBatchRechargeReq?.cret_images"
                                   (fileChange)="prepayPaymnetFill($event)"></app-new-file-upload>
            </ul>
          </div>
        </div>
        <p class="font12 text-center black mb40">
          支付成功后，{{paymentAmount}}元预付账户充值才会生效，否则不生效
        </p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20" style="background-color: rgba(242, 90, 99, 1);"
                  (click)="confirmPrepayRecharge(paymentType,prepayposPaymnet,prepaygatherSuccess)">
            确认已收
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="prepayposPaymnet.hide()">取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 账户收款 -->
<div class="modal fade" bsModal #prepayAccountPayment="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="prepayAccountPayment.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <span class="payIcon black">
             <img class="payImg" src="../../../../assets/img/money/cash.png">
              客商账户
          </span>
          <small class="text-orange">收款金额：<span
            class="money-color">{{paymentAmount}}</span>元</small>
        </h2>
        <p class="font12 text-center black mb20">
          支付成功后，{{paymentAmount}}元预付账户充值才会生效，否则不生效</p>
        <p class="b-muted font12 text-center mb20">确认后立即入账且不可更改，是否确认已收？</p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-warning mr20" style="background-color: rgba(242, 90, 99, 1);"
                  (click)="confirmPrepayRecharge(paymentType,prepayAccountPayment,prepaygatherSuccess)">
            确认已收
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="prepayAccountPayment.hide()">取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 6.2 收款成功 -->
<div class="modal fade" bsModal #prepaygatherSuccess="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作提醒</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="prepaygatherSuccess.hide()">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <h2 class="red text-center mb20">
          <small class="text-orange" style="font-weight: 400;font-size: 28px;color: #F25A63;">
            收款成功！
            <ng-container *ngIf="paymentType==='CHECK' || paymentType === 'TRANSFER' || paymentType === 'POS' || paymentType === 'CASH'">
              需财务人员核销后到账！
            </ng-container>
          </small>
        </h2>
        <h2 class="red text-center mb10">
          <small class="text-orange">收款金额：</small>
          {{paymentAmount}}
          <small class="text-orange">元</small>
        </h2>

      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20" style="background-color: rgba(242, 90, 99, 1);"
                  (click)="printPaymentCret()">
            <i class="fa fa-print"></i>打印凭证
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="prepaygatherSuccess.hide()">关闭
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 6.3 账单订单号绑定页面 -->
<div class="modal fade" bsModal #prepaybindOrderBill="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
     role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-lg">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>操作收款</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="prepaybindOrderBill.hide();checkOrderNo(null)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="red mb10">已到账{{orderDtos?.length}}笔相同金额的收款，请选择并确认：</p>
        <div class="table-box">
          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>请选择</th>
              <th>商户账单号</th>
              <th>支付金额</th>
              <th>支付时间</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of orderDtos">
              <td><input type="radio" name="check_order_no" value="{{item.order_no}}"
                         (click)="checkOrderNo(item.order_no)"></td>
              <td>{{item.order_id}}</td>
              <td>{{item.order_amount}}</td>
              <td>{{item.pay_time |date:'yyyy-MM-dd HH:mm:ss'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <p class="text-orange font12">
          多笔相同金额请核对付款人和商户订单号信息，未找到客户提供的订单号？
          <button class="btn b-blues" style="background-color: white;border: 1px solid  #3366FF;"
                  (click)="showQueryByOrderId(prepayorderQuery,prepaybindOrderBill)">点击进入
          </button>
        </p>
      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button *ngIf="check_order_no" type="button" class="btn btn-primary mr20"
                  style="background-color: rgba(242, 90, 99, 1);width: 70px;"
                  (click)="reletedPrepayAndOrder(check_order_no,prepaybindOrderBill,prepaygatherSuccess)">
            确认
          </button>
          <button *ngIf="!check_order_no" type="button" class="btn btn-secondary mr20"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;">
            确认
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="prepaybindOrderBill.hide();checkOrderNo(null)"> 取消
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 6.3.1 商户订单号查询页面 -->
<div class="modal fade" bsModal #prepayorderQuery="bs-modal" style="z-index: 999999;background: rgba(0,0,0,0.4);"
     [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-warning modal-sm">
    <div class="modal-content">
      <div class="modal-header pay_headerbg">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>收款操作</h4>
        <button type="button" class="close pull-right" aria-label="Close"
                (click)="closeOrderQueryDialog(prepayorderQuery)">
          <span><i class="icon icon-close" aria-hidden="true"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-left mt-4 mb20 black" style="padding-left: 10%;">请输入用户支付结果页面中显示的商户订单号：
        </p>
        <div class="row mb10">
          <div class="col-md-12" style="padding-left: 13%;">
            <input type="text" class="w-90" [(ngModel)]="orderId">
          </div>
        </div>
        <p class="text-orange font12 text-left mb20" *ngIf="orderRemind" style="padding-left: 10%;">
          未查到该订单号，请确认订单号是否正确</p>

      </div>
      <div class="modal-footer">
        <div class="text-center mb20">
          <button type="button" class="btn btn-primary mr20" style="background-color: rgba(242, 90, 99, 1);width: 70px;"
                  (click)="prepayQueryByOrderId(prepayorderQuery,prepaygatherSuccess)">
            确认
          </button>
          <button type="button" class="btn btn-secondary"
                  style="background-color: rgba(201, 201, 201, 1);color: white;width: 70px;"
                  (click)="closeOrderQueryDialog(prepayorderQuery)"> 关闭
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
