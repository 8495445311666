<div class="setbox padd15">
  <div class="row m-0">
    <div class="col-12 row m-0 change_bg p-0">
      <div class="col-6 text-center p-2 pointer {{sign_type === 'normal'?'active':''}}" (click)="openLeaseTab()">客户合同
      </div>
      <div class="col-6 text-center p-2 pointer {{sign_type === 'free'?'active':''}}" (click)="openInternalLeaseTab()">内部免租合同
      </div>
    </div>
    <div class="col-12 mb10 mt10">
      <button [routerLink]="['/system_manage/lease/sign/add']" class="btn btn-info" type="button"
              *ngIf="btn_privileges.LEASE_ADD && sign_type === 'normal'">
        <i class="fa fa-plus" aria-hidden="true"></i>新签房间/床位
      </button>
      <button [routerLink]="['/system_manage/lease/freeSign/add']" class="btn btn-info" type="button"
              *ngIf="btn_privileges.LEASE_ADD && sign_type === 'free'">
        <i class="fa fa-plus" aria-hidden="true"></i>新签房间
      </button>
      <button (click)="showDialog()" class="btn btn-warning" style="margin-left: 10px" type="button"
              *ngIf="btn_privileges.LEASE_BILL">
        <i class="fa fa-rmb" aria-hidden="true"></i>收款
      </button>
      <!--<button [routerLink]="['/system_manage/signed/garagelist']" class="btn btn-warning" style="margin-left: 10px" type="button">
        <i class="fa fa-refresh" aria-hidden="true" *ngIf="btn_privileges.TURN_TO_CARPORT_ROOM"></i>切换至车库/位
      </button>-->
      <button class="btn btn-primary" style="margin-left: 10px" type="button"
              (click)="settleModalShow(waterModal, true)"
              *ngIf="btn_privileges.RE_MARK_WATER && sign_type === 'normal'">重新打水印
      </button>
    </div>
  </div>
  <div *ngIf="sign_type === 'normal'">
    <div class="row">
      <div class="col-12 col-md-3">
        <label>查询条件</label>
        <div class="wit200">
          <input [(ngModel)]="bookingListRequest.search_content" class="form-control" placeholder="请输入姓名、手机号、合同号">
        </div>
      </div>
      <div class="col-12 col-md-1">
        <label>时间范围</label>
        <div>
          <select [(ngModel)]="lease_date_type" class="w-100">
            <option *ngFor="let leaseDateType of lease_date_type_list" [value]="leaseDateType.code">
              {{leaseDateType.desc}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div class="form-group">
          <label>&nbsp;</label>
          <div>
            <div class="d-inline-block w-45">
              <my-date-picker placeholder="年/月/日" [(ngModel)] = "start_time"
                              [options]="endTimeOptionsTomorrow">
              </my-date-picker>
            </div>
            <div class="ml-2 mr-2 d-inline-block align-top">－</div>
            <div class="d-inline-block w-45">
              <my-date-picker placeholder="年/月/日" [(ngModel)] = "end_time"
                              [options]="endTimeOptionsTomorrow">
              </my-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-1">
        <label>状态</label>
        <div>
          <select [(ngModel)]="lease_status" (change)="statusSet($event, null)" class="w-100">
            <option [value]="-1">全部</option>
            <option *ngFor="let business_status of lease_business_status_list" [value]="business_status.code">
              {{business_status.desc}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-1">
        <label>合同类型</label>
        <div>
          <select [(ngModel)]="lease_type" class="w-100">
            <option [value]="-1">全部</option>
            <option *ngFor="let leaseType of lease_type_list" [value]="leaseType.code">
              {{leaseType.value}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-1">
        <label>房间类型</label>
        <div>
          <select [(ngModel)]="roomDataType" class="w-100">
            <option  value="ALL">全部</option>
            <option  value="ROOM">房间</option>
            <option  value="PUBLIC">公区</option>
            <option  value="SHOP">商铺</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-1">
        <label>是否为人才房</label>
        <div>
          <select [(ngModel)]="ihome" class="w-100">
            <option [value]="2">全部</option>
            <option [value]="1">是</option>
            <option [value]="0">否</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-2">
        <label>&nbsp;&nbsp;</label>
        <div>
          <button (click)="bookingListRequest.page_num = 1;searchBookingList()" class="btn btn-info" type="button">
            <i class="fa fa-search"></i>搜索
          </button>
          <button class="btn btn-warning" (click)="downloadList()" *ngIf="btn_privileges.LEASE_DOWNLOAD">
            <i class="fa fa-download"></i>下&nbsp;载
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 mb10 text-right">
        <div>
          排序:&nbsp;&nbsp;
          <span style="cursor: pointer;margin-right: 15px;" (click)="checkStatusOrderBy('SIGNTYPE',true)"
                *ngIf="sign_time"
                [ngClass]="{'status_color': check_status === 'SIGNTYPE'}">签约时间↑</span>
          <span style="cursor: pointer;margin-right: 15px;" (click)="checkStatusOrderBy('SIGNTYPE',false)"
                *ngIf="!sign_time"
                [ngClass]="{'status_color': check_status === 'SIGNTYPE'}">签约时间↓</span>
          <span style="cursor: pointer;margin-right: 15px;" (click)="checkStatusOrderBy('BEGINTYPE',true)"
                *ngIf="begin_time"
                [ngClass]="{'status_color': check_status === 'BEGINTYPE'}">合同开始时间↑</span>
          <span style="cursor: pointer;margin-right: 15px;" (click)="checkStatusOrderBy('BEGINTYPE',false)"
                *ngIf="!begin_time"
                [ngClass]="{'status_color': check_status === 'BEGINTYPE'}">合同开始时间↓</span>
          <span style="cursor: pointer;" (click)="checkStatusOrderBy('RETREATTYPE',true)" *ngIf="retreat_time"
                [ngClass]="{'status_color': check_status === 'RETREATTYPE'}">退租时间↑</span>
          <span style="cursor: pointer;" (click)="checkStatusOrderBy('RETREATTYPE',false)" *ngIf="!retreat_time"
                [ngClass]="{'status_color': check_status === 'RETREATTYPE'}">退租时间↓</span>
        </div>
      </div>
    </div>
    <div class="scrollx-head">
      <!-- table -->
      <table class="table table-bordered table-striped scrollx-body">
        <thead>
        <tr>
          <th class="show2">合同号</th>
          <!-- <th>签约类型</th>-->
          <th>签约时间</th>
          <th class="show2">合同租期</th>
          <th>签约间数</th>
          <th>已退间数</th>
          <th>签约人</th>
          <th class="show2">联系人</th>
          <th>联系电话</th>
          <th>状态</th>
          <th>渠道来源</th>
          <th>房间类型</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <ng-container
          *ngIf="btn_privileges.LEASE_QUERY && bookingListResponse && bookingListResponse?.lease_booking_list && bookingListResponse?.lease_booking_list.length > 0">
          <tr *ngFor="let info of bookingListResponse.lease_booking_list | paginate: {itemsPerPage: bookingListRequest.page_size,
            currentPage: bookingListRequest.page_num,totalItems: bookingListResponse.data_count }">
            <td class="show2">{{info?.contract_num}}</td>
            <!--<td>房间</td>-->
            <td>{{info?.create_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
            <td class="show2">{{info?.start_time | date:'yyyy.MM.dd'}} - {{info?.end_time | date:'yyyy.MM.dd'}}</td>
            <td>{{info?.room_count}}</td>
            <td>{{info?.check_out_room_count}}</td>
            <ng-container *ngIf="info?.lease_subject_info_dto?.subject_type === 'PERSON'">
              <td>
                {{info?.lease_subject_info_dto?.name}}
              </td>
              <td class="show2">--</td>
              <td>
                <ng-container *ngIf="btn_privileges?.QUERY_USER_PRIVAYE">
                  {{info?.lease_subject_info_dto?.tel}}
                </ng-container>
                <ng-container *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE">
                  {{info?.lease_subject_info_dto?.tel_conceal}}
                </ng-container>
              </td>
            </ng-container>
            <ng-container *ngIf="info?.lease_subject_info_dto?.subject_type === 'COMPANY'">
              <td>
                {{info?.lease_subject_info_dto?.company_name}}
              </td>
              <td class="show2">{{info?.lease_subject_info_dto?.name}}</td>
              <td>
                <ng-container *ngIf="btn_privileges?.QUERY_USER_PRIVAYE">
                  {{info?.lease_subject_info_dto?.tel}}
                </ng-container>
                <ng-container *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE">
                  {{info?.lease_subject_info_dto?.tel_conceal}}
                </ng-container>
              </td>
            </ng-container>
            <td>
            <span class="badge badge-pill" [style.background-color]="getColorByBusinessStatus(info.business_status)">
              {{info.business_status_desc}}
            </span>
            </td>
            <td>
            <span>
              {{info.source_name}}
            </span>
            </td>
            <td>
              <span>
                {{info.room_data_type_text}}
              </span>
            </td>
            <td>

              <div class="show1">
                <button type="button" *ngIf="btn_privileges.LEASE_ADD && 'UNCOMMITTED' == info?.status_code"
                        [routerLink]="['../sign-change/',info?.serial_number,info?.status_code]"
                        [queryParams]="{'lease_id':info.serial_number}" class="btn btn-info">
                  <i class="fa fa-cogs"></i>
                </button>
                <button type="button"
                        *ngIf="((btn_privileges.LEASE_DETAIL || btn_privileges.CARPORT_LEASE_QUERY) && 'UNCOMMITTED' != info?.status_code)"
                        [routerLink]="['../sign-change/',info?.serial_number,info?.status_code]" class="btn btn-info">
                  <i class="fa fa-eye"></i>
                </button>
              </div>
              <div class="show2">
                <!--   租约未提交状态下 点击操作跳转的是租约编辑页面 -->
                <button type="button" *ngIf="btn_privileges.LEASE_ADD && 'UNCOMMITTED' == info?.status_code"
                        [routerLink]="['../sign-change/',info?.serial_number,info?.status_code]"
                        [queryParams]="{'lease_id':info.serial_number}" class="btn btn-info">
                  <i class="fa fa-cogs"></i>查看/操作
                </button>
                <button type="button"
                        *ngIf="((btn_privileges.LEASE_DETAIL || btn_privileges.CARPORT_LEASE_QUERY) && 'UNCOMMITTED' != info?.status_code)"
                        [routerLink]="['../sign-change/',info?.serial_number,info?.status_code]" class="btn btn-info">
                  <i class="fa fa-eye"></i>查看
                </button>
              </div>

            </td>
          </tr>
        </ng-container>
        <tr
          *ngIf="!btn_privileges.LEASE_QUERY || bookingListResponse?.lease_booking_list == null || bookingListResponse?.lease_booking_list.length === 0">
          <td colspan="12" class="show2 text-center">本次未查询到相关数据</td>
          <td colspan="7" class="show1 text-center">本次未查询到相关数据</td>
        </tr>
        </tbody>
      </table>
    </div>


    <div
      *ngIf="btn_privileges.LEASE_QUERY && bookingListResponse?.lease_booking_list != null && bookingListResponse?.lease_booking_list.length > 0"
      class="text-right pages">
      <pagination-controls (pageChange)="pageChange($event)" directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                           screenReaderCurrentLabel="You're on page"></pagination-controls>
      <div class="text-right">共有{{bookingListResponse?.data_count}}条租约信息</div>
    </div>
  </div>
  <div *ngIf="sign_type === 'free'">
    <div class="row">
      <div class="col-12 col-md-3 mb10">
        <label>查询条件</label>
        <div class="wit200">
          <input [(ngModel)]="bookingListRequest.search_content" class="form-control" placeholder="请输入姓名、手机号或合同号">
        </div>
      </div>
      <div class="col-12 col-md-2 mb10">
        <label>状态</label>
        <div>
          <select [(ngModel)]="lease_status" (change)="statusSet($event, null)" class="w-100">
            <option [value]="-1">全部</option>
            <option *ngFor="let business_status of lease_business_status_list" [value]="business_status.code">
              {{business_status.desc}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-2 mb10">
        <label>&nbsp;&nbsp;</label>
        <div>
          <button (click)="bookingListRequest.page_num = 1;searchBookingList()" class="btn btn-info" type="button">
            <i class="fa fa-search"></i>搜索
          </button>
        </div>
      </div>
      <div class="col-12 col-md-5 mb10">
        <label>&nbsp;&nbsp;</label>
        <div>
          排序:&nbsp;&nbsp;
          <span style="cursor: pointer;margin-right: 15px;" (click)="checkStatusOrderBy('SIGNTYPE',true)"
                *ngIf="sign_time"
                [ngClass]="{'status_color': check_status === 'SIGNTYPE'}">签约时间↑</span>
          <span style="cursor: pointer;margin-right: 15px;" (click)="checkStatusOrderBy('SIGNTYPE',false)"
                *ngIf="!sign_time"
                [ngClass]="{'status_color': check_status === 'SIGNTYPE'}">签约时间↓</span>
          <span style="cursor: pointer;margin-right: 15px;" (click)="checkStatusOrderBy('BEGINTYPE',true)"
                *ngIf="begin_time"
                [ngClass]="{'status_color': check_status === 'BEGINTYPE'}">合同开始时间↑</span>
          <span style="cursor: pointer;margin-right: 15px;" (click)="checkStatusOrderBy('BEGINTYPE',false)"
                *ngIf="!begin_time"
                [ngClass]="{'status_color': check_status === 'BEGINTYPE'}">合同开始时间↓</span>
          <span style="cursor: pointer;" (click)="checkStatusOrderBy('RETREATTYPE',true)" *ngIf="retreat_time"
                [ngClass]="{'status_color': check_status === 'RETREATTYPE'}">退租时间↑</span>
          <span style="cursor: pointer;" (click)="checkStatusOrderBy('RETREATTYPE',false)" *ngIf="!retreat_time"
                [ngClass]="{'status_color': check_status === 'RETREATTYPE'}">退租时间↓</span>
        </div>
      </div>
    </div>
    <!-- table -->
    <table class="table table-bordered table-striped">
      <thead>
      <tr>
        <th class="show2">合同号</th>
        <!-- <th>签约类型</th>-->
        <th>签约时间</th>
        <th class="show2">合同租期</th>
        <th>签约间数</th>
        <th>已退间数</th>
        <th>签约人</th>
        <th class="show2">联系人</th>
        <th>联系电话</th>
        <th>状态</th>
        <th>操作</th>
      </tr>
      </thead>
      <tbody>
      <ng-container
        *ngIf="btn_privileges.LEASE_QUERY && bookingListResponse && bookingListResponse?.lease_booking_list && bookingListResponse?.lease_booking_list.length > 0">
        <tr *ngFor="let info of bookingListResponse.lease_booking_list | paginate: {itemsPerPage: bookingListRequest.page_size,
            currentPage: bookingListRequest.page_num,totalItems: bookingListResponse.data_count }">
          <td class="show2">{{info?.contract_num}}</td>
          <!--<td>房间</td>-->
          <td>{{info?.create_time | date:'yyyy-MM-dd HH:mm:ss'}}</td>
          <td class="show2">{{info?.start_time | date:'yyyy.MM.dd'}} - {{info?.end_time | date:'yyyy.MM.dd'}}</td>
          <td>{{info?.room_count}}</td>
          <td>{{info?.check_out_room_count}}</td>
          <ng-container *ngIf="info?.lease_subject_info_dto?.subject_type === 'PERSON'">
            <td>
              {{info?.lease_subject_info_dto?.name}}
            </td>
            <td class="show2">--</td>
            <td>
              <ng-container *ngIf="btn_privileges?.QUERY_USER_PRIVAYE">
                {{info?.lease_subject_info_dto?.tel}}
              </ng-container>
              <ng-container *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE">
                {{info?.lease_subject_info_dto?.tel_conceal}}
              </ng-container>
            </td>
          </ng-container>
          <ng-container *ngIf="info?.lease_subject_info_dto?.subject_type === 'COMPANY'">
            <td>
              {{info?.lease_subject_info_dto?.company_name}}
            </td>
            <td class="show2">{{info?.lease_subject_info_dto?.name}}</td>
            <td>
              <ng-container *ngIf="btn_privileges?.QUERY_USER_PRIVAYE">
                {{info?.lease_subject_info_dto?.tel}}
              </ng-container>
              <ng-container *ngIf="!btn_privileges?.QUERY_USER_PRIVAYE">
                {{info?.lease_subject_info_dto?.tel_conceal}}
              </ng-container>
            </td>
          </ng-container>
          <td>
            <span class="badge badge-pill" [style.background-color]="getColorByBusinessStatus(info.business_status)">
              {{info.business_status_desc}}
            </span>
          </td>
          <td>

            <div class="show1">
              <button type="button" *ngIf="btn_privileges.LEASE_ADD && 'UNCOMMITTED' == info?.status_code"
                      [routerLink]="['../sign-change/',info?.serial_number,info?.status_code]"
                      [queryParams]="{'lease_id':info.serial_number}" class="btn btn-info">
                <i class="fa fa-cogs"></i>
              </button>
              <button type="button"
                      *ngIf="((btn_privileges.LEASE_DETAIL || btn_privileges.CARPORT_LEASE_QUERY) && 'UNCOMMITTED' != info?.status_code)"
                      [routerLink]="['../sign-change/',info?.serial_number,info?.status_code]" class="btn btn-info">
                <i class="fa fa-eye"></i>
              </button>
            </div>
            <div class="show2">
              <!--   租约未提交状态下 点击操作跳转的是租约编辑页面 -->
              <button type="button" *ngIf="btn_privileges.LEASE_ADD && 'UNCOMMITTED' == info?.status_code"

                      [routerLink]="['/system_manage/lease/freeSign/tolease']"
                      [queryParams]="{'lease_id':info.serial_number}" class="btn btn-info">
                <i class="fa fa-cogs"></i>查看/操作
              </button>
              <button type="button"
                      *ngIf="((btn_privileges.LEASE_DETAIL || btn_privileges.CARPORT_LEASE_QUERY) && 'UNCOMMITTED' != info?.status_code)"
                      [routerLink]="['../sign-change/',info?.serial_number,info?.status_code]" class="btn btn-info">
                <i class="fa fa-eye"></i>查看
              </button>
            </div>

          </td>
        </tr>
      </ng-container>
      <tr
        *ngIf="!btn_privileges.LEASE_QUERY || bookingListResponse?.lease_booking_list == null || bookingListResponse?.lease_booking_list.length === 0">
        <td colspan="10" class="show2 text-center">本次未查询到相关数据</td>
        <td colspan="7" class="show1 text-center">本次未查询到相关数据</td>
      </tr>
      </tbody>
    </table>

    <div
      *ngIf="btn_privileges.LEASE_QUERY && bookingListResponse?.lease_booking_list != null && bookingListResponse?.lease_booking_list.length > 0"
      class="text-right pages">
      <pagination-controls (pageChange)="pageChange($event)" directionLinks="true" previousLabel="上一页" nextLabel="下一页"
                           screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                           screenReaderCurrentLabel="You're on page"></pagination-controls>
      <div class="text-right">共有{{bookingListResponse?.data_count}}条租约信息</div>
    </div>
  </div>
</div>

<!-- 重新打水印 -->
<div class="modal fade" bsModal #waterModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="bg-warning">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>重新打水印</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="waterModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-danger mb-3">此功能用于给已经存在在的合同，按最新的水印样式，重新打一遍附件水印</p>
        <p class="mb-2">请选择需要打水印的合同号</p>
        <textarea class="w-100" rows="3" [(ngModel)]="leaseContactStr"></textarea>
        <p class="text-secondary">如果有多个合同，请以逗号分隔</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary " type="button" (click)="leaseImageHandle(waterModal,staticModal2,staticModal3)">
          重新打水印
        </button>
        <button class="btn btn-secondary " type="button" (click)="cancelLeaseImageHandle(waterModal)"> 取消
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade " bsModal #staticModal2="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999; background: rgba(0, 0, 0, 0.4);">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="bg-warning">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>重新打水印</h4>
      </div>
      <div class="modal-body">
        <p class="text-danger mb-4 text-center">请不要关闭窗口或刷新页面</p>
        <p class="text-danger mb-4 text-center">正在重新打水印，结束后会自动关闭窗口</p>
      </div>
    </div>
  </div>
</div>
<div class="modal fade " bsModal #staticModal3="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
     aria-labelledby="dialog-static-name" style="z-index: 99999; background: rgba(0, 0, 0, 0.4);">
  <div class="modal-dialog modal-primary modal-sm">
    <div class="modal-content">
      <div class="bg-danger">
        <h4 class="modal-title pull-left"><i class="fa fa-info-circle" aria-hidden="true"></i>重新打水印</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal3.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-danger mb-4 text-center">无效合同号：
          <span *ngFor="let item of openResults,let i = index">
            {{item}}
            <span *ngIf="i !== openResults.length -1">,</span>
          </span>
        </p>
      </div>
    </div>
  </div>
</div>

<app-dialog></app-dialog>
