import {Component, OnInit, ViewChild} from '@angular/core';
import {BookingListRequest, BookingListResponse, LeaseTypeDTO} from '../../../model/booking/booking-list.model';
import {BookingService} from '../../../service/booking/booking.service';
import {AuthService} from '../../../service/common/auth.service';
import {DialogComponent} from "../../shared/dialog/dialog.component";
import {BaseEnum} from '../../../model/modal/base-model';
import {BaseInfoService} from '../../../service/common/base-info.service';
import {BillReconciliationRequest} from "../../../model/finance/finance.model";
import {LogService} from "../../../service/log/log.service";
import {ImageHandleService} from "../../../service/image/image.handle.service";
import {IMyDpOptions} from "../../third-party/mydatepicker/src/lib/interfaces";
import {addDate, getTimeFromPlugin} from "../../../common/date.util";
import {WorkService} from "../../../service/common/work.service";

declare var baidutongji:any;
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  btn_privileges: any = {
    'LEASE_QUERY': false,
    'LEASE_ADD': false,
    'LEASE_BILL': false,
    'TURN_TO_CARPORT_ROOM': false,
    'RE_MARK_WATER': false,
    'LEASE_DETAIL': false,
    'CARPORT_LEASE_QUERY': false,
    'QUERY_USER_PRIVAYE': false,
    'LEASE_DOWNLOAD': false,
    'LEASE_LIST_FILTER': false,
  };


  bookingListRequest: BookingListRequest = new BookingListRequest();
  bookingListResponse: BookingListResponse = new BookingListResponse();
  @ViewChild(DialogComponent, { static: true }) dialogComponent: DialogComponent;

  lease_business_status_list: BaseEnum[] = [];

  lease_status: string;

  //排序
  check_status: string;
  sign_time: boolean = false;
  sign_count: number = 0;
  begin_time: boolean = true;
  begin_count: number = 0;
  retreat_time: boolean = true;
  retreat_count: number = 0;

  // 第一个租约开始时间
  initial_date: Date;
  // 从什么时候开始夜审
  settle_start_time: any;
  // 项目名
  project_name: string;
  // 重新夜审弹窗
  settle_modal: any;
  isPasswordNullError: boolean = true;

  isHasNoBill: boolean = true;
  isPasswordError: boolean = false;
  isPasswordTimeError: boolean = false;
  isShowRecBody: boolean = false;
  billAmountError: boolean = false;
  billReconciliationRequest: BillReconciliationRequest = new BillReconciliationRequest();

  // 合同号
  leaseContactStr: string;
  isLeaseImageSubmit: boolean;
  openResults = [];
  sign_type:string = 'normal';
  lease_type_list: Array<LeaseTypeDTO>;
  lease_type: string;
  roomDataType = "ALL";
  ihome = 2;
  start_time: any;
  end_time: any;
  now: Date = new Date();
  tomorrow = addDate(this.now, 1);
  public endTimeOptionsTomorrow: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    showClearDateBtn: true,
  };
  lease_date_type_list: BaseEnum[] = [];
  lease_date_type: string = 'LEASE_END_TIME';


  constructor(private bookingService: BookingService,
              private baseInfoService: BaseInfoService,
              private authService: AuthService,
              private logService: LogService,
              private imageHandleService: ImageHandleService,
              private workService: WorkService,) {
  }

  ngOnInit() {
    this.authService.getOperatePrivilege(this.btn_privileges);
    this.lease_business_status_list = this.baseInfoService.getLeaseBusinessStatusListRoom(this.btn_privileges.LEASE_LIST_FILTER);
    this.lease_date_type_list = this.baseInfoService.getLeaseDateType();
    this.baseInfoService.getLeaseTypeList2().then(data => {
      this.lease_type_list = data.lease_type_list;
    });
    this.bookingListRequest.search_type = "LEASE";
    this.bookingListRequest.page_num = 1;
    this.bookingListRequest.page_size = 20;
    baidutongji("签约管理");
    this.lease_type = '-1';
    this.lease_status = '-1';
    this.check_status = 'SIGNTYPE';
    this.sign_count = 1;
    this.bookingListRequest.order_by_type = this.check_status;
    this.bookingListRequest.order_by_desc = false;

    this.searchBookingList();
  }

  searchBookingList() {
    if (this.start_time == null) {
      delete this.bookingListRequest.start_time;
    } else {
      let start_time = getTimeFromPlugin(this.start_time.formatted);
      this.bookingListRequest.start_time = start_time;
    }
    if (this.end_time == null) {
      delete this.bookingListRequest.end_time;
    } else {
      let end_time = getTimeFromPlugin(this.end_time.formatted);
      this.bookingListRequest.end_time = end_time;
    }
    if (this.bookingListRequest.start_time != null && this.bookingListRequest.end_time == null) {
      this.workService.showErrorModal('查询结束时间不能为空');
      return ;
    }
    if (this.bookingListRequest.start_time == null && this.bookingListRequest.end_time != null) {
      this.workService.showErrorModal('查询开始时间不能为空');
      return ;
    }
    if (this.bookingListRequest.start_time != null && this.bookingListRequest.end_time != null) {
      if (this.bookingListRequest.end_time < this.bookingListRequest.start_time) {
        this.workService.showErrorModal("查询结束时间应大于开始时间");
        return;
      }
    }
    if (this.lease_type != null && this.lease_type !== '-1') {
      this.bookingListRequest.lease_type = this.lease_type;
    } else {
      delete this.bookingListRequest.lease_type;
    }
    if (this.lease_date_type != null) {
      this.bookingListRequest.lease_date_type = this.lease_date_type;
    } else {
      delete this.bookingListRequest.lease_date_type;
    }
    this.bookingListRequest.ihome = this.ihome;
    this.bookingListRequest.room_data_type = this.roomDataType;
    this.bookingService.leaseBookingListWithLoading(this.bookingListRequest).then(data => {
      this.bookingListResponse = data;
    });
  }

  statusSet(event, code) {
    this.bookingListRequest.status = [];
    this.lease_status = event.target.value;
    this.bookingListRequest.page_num = 1;
    if(this.lease_status !== '-1'){
      this.bookingListRequest.status.push(this.lease_status);
    }

    /*if (this.bookingListRequest.status == null) {
      this.bookingListRequest.status = [];
    }
    if (event.target.checked) {
      // 添加状态
      this.bookingListRequest.status.push(code);
    } else {
      // 删除状态
      for (let i = 0; i < this.bookingListRequest.status.length; i++) {
        if (this.bookingListRequest.status[i] === code) {
          this.bookingListRequest.status.splice(i, 1);
        }
      }
    }*/
    this.searchBookingList();
  }

  pageChange(currentPage: number): void {
    this.bookingListRequest.page_num = currentPage;
    this.searchBookingList();
  }

  //收款
  showDialog() {
    baidutongji("收款");
    this.dialogComponent.openNoContractGatherDialog();
  }

  getColorByBusinessStatus(business_status): string {
    return this.baseInfoService.getColorByBusinessStatus(business_status);
  }

  //切换排序
  checkStatusOrderBy(type: string,ischeck: boolean){
    this.check_status = type;
    if(type === 'SIGNTYPE'){
      if(this.sign_count !== 0){
        this.sign_time = !ischeck;
        this.bookingListRequest.order_by_desc = !ischeck;
      }else{
        this.sign_time = ischeck;
        this.bookingListRequest.order_by_desc = ischeck;
      }
      this.sign_count += 1;
      this.begin_count = 0;
      this.retreat_count = 0;
    }else if(type === 'BEGINTYPE'){
      if(this.begin_count !== 0) {
        this.begin_time = !ischeck;
        this.bookingListRequest.order_by_desc = !ischeck;
      }else{
        this.begin_time = ischeck;
        this.bookingListRequest.order_by_desc = ischeck;
      }
      this.begin_count += 1;
      this.sign_count = 0;
      this.retreat_count = 0;
    }else if(type === 'RETREATTYPE'){
      if(this.retreat_count !== 0) {
        this.retreat_time = !ischeck
        this.bookingListRequest.order_by_desc = !ischeck;
      }else{
        this.retreat_time = ischeck;
        this.bookingListRequest.order_by_desc = ischeck;
      }
      this.retreat_count += 1;
      this.begin_count = 0;
      this.sign_count = 0;
    }
    this.bookingListRequest.order_by_type = this.check_status;
    this.searchBookingList();
  }

  /*************************** 新增夜审按钮 **************************************/
  // 重新夜审弹窗显示
  settleModalShow(settle_modal: any, is_show: boolean) {
    this.settle_modal = settle_modal;
    if (is_show) {
      this.settle_start_time = '';
      this.cancelBillReconciliationRequest();
      this.settle_modal.show();
      this.isPasswordNullError = true;
    } else {
      this.settle_modal.hide();
    }
  }

  /**
   * 重新打水印
   */
  leaseImageHandle(waterModel: any, tipModal: any, dangerModel: any) {
    if (this.leaseContactStr) {
      let resultList = this.leaseContactStr.replace(/[，]/ig, ',');
      let requestData = resultList.split(',');
      this.isLeaseImageSubmit = true;
      waterModel.hide();
      tipModal.show();
      setTimeout(() => {
        this.imageHandleService.leaseImageHandle({lease_contracts: requestData}).then(response => {
          if (response) {
            // 部分成功
            if (response.is_success === 'PART_SUCCESS') {
              tipModal.hide();
              this.openResults = response.results;
              dangerModel.show();
            } else {
              this.isLeaseImageSubmit = false;
              tipModal.hide();
            }
          }
        }).catch(error => {
          this.logService.error(error);
        });
      }, 2000);
    }
    this.leaseContactStr = null;
  }

  /**
   * 取消系统调账
   * @param model
   */
  cancelBillReconciliationRequest(model?: any) {
    this.isHasNoBill = true;
    this.isPasswordError = false;
    this.isPasswordTimeError = false;
    this.isShowRecBody = false;
    this.billAmountError = false;
    this.billReconciliationRequest = new BillReconciliationRequest();
    if (model) {
      model.hide();
    }
  }

  /**
   * 取消打印
   * @param waterModel
   */
  cancelLeaseImageHandle(waterModel: any) {
    waterModel.hide();
    this.leaseContactStr = null;
  }

  downloadList() {
    this.bookingListRequest.page_num = 1;
    if (this.start_time == null) {
      delete this.bookingListRequest.start_time;
    } else {
      let start_time = getTimeFromPlugin(this.start_time.formatted);
      this.bookingListRequest.start_time = start_time;
    }
    if (this.end_time == null) {
      delete this.bookingListRequest.end_time;
    } else {
      let end_time = getTimeFromPlugin(this.end_time.formatted);
      this.bookingListRequest.end_time = end_time;
    }
    if (this.bookingListRequest.start_time != null && this.bookingListRequest.end_time != null) {
      if (this.bookingListRequest.end_time < this.bookingListRequest.start_time) {
        this.workService.showErrorModal("查询结束时间应大于开始时间");
        return;
      }
    }
    if (this.lease_date_type != null) {
      this.bookingListRequest.lease_date_type = this.lease_date_type;
    }
    if (this.lease_type != null && this.lease_type !== '-1') {
      this.bookingListRequest.lease_type = this.lease_type;
    } else {
      delete this.bookingListRequest.lease_type;
    }
    this.bookingService.leaseBookingListDownload(this.bookingListRequest);
  }

  openLeaseTab() {
    this.start_time = null;
    this.end_time = null;
    this.sign_type='normal';
    this.bookingListRequest.search_type = 'LEASE';
    this.bookingListRequest.page_num = 1;
    this.bookingListRequest.page_size = 20;
    this.roomDataType = "ALL";
    this.searchBookingList();
  }

  openInternalLeaseTab() {
    this.start_time = null;
    this.end_time = null;
    this.sign_type='free';
    this.bookingListRequest.search_type = 'INTERNAL_LEASE';
    this.bookingListRequest.page_num = 1;
    this.bookingListRequest.page_size = 20;
    this.roomDataType = "ALL";
    this.searchBookingList();
  }
}
