import {PageReq, PageResp} from "../page/page";
import {RoomInfoDTO} from "../room/room.model";


export class WorkRemindListDTO {
  // @Remark("待办事项ID")
  work_remind_id?: number;
  // @Remark("待办事项标题")
  title?: string;
  // @Remark("项目名称")
  project_name?: string;
  // @Remark("待办开始时间")
  start_time?: number;
  // @Remark("待办关闭时间")
  close_time?: number;
  // @Remark("待办事项类型")
  rule_type?: string;
  // @Remark("待办事项状态:未处理：WAITING，待复查：WAIT_CHECK，已关闭：OFF")
  service_status?: string;
  // @Remark("是否只读false：只读，true：非只读")
  view?: boolean;
  // 待办事项类型code
  rule_type_code?: string;
}

export class WorkRemindFireDetailDTO extends WorkRemindListDTO {
  notice_roles?: string;
  notice_types?: string;

  // @Remark("消防数据")
  fire_warn_info?: FireSystemComponentWarnBizDTO ;

  log_list?: Array<WorkRemindFireDetailDTO>;
}

export class WorkRemindFireUnitDTO {
  // @Remark("楼栋ID")
  unit_id?: number;
  // @Remark("楼栋名称")
  unit_name?: string;
  project_id?: number;

  // @Remark("待办列表")
  work_remind_fire_detail_dtolist?: Array<WorkRemindFireDetailDTO> ;
}

export class WorkRemindFireWarnListResponse {

  warn_present_list?: Array<WorkRemindFireDetailDTO>;
  warn_present_unit_list?: Array<WorkRemindFireUnitDTO>;
  warn_history_list?: Array<WorkRemindFireDetailDTO>;

  room_info_dtolist?: Array<RoomInfoDTO>;

  project_count?: number;
  warning_project_count?: number;
}


export class WorkRemindRuleTypeDTO {
  id?: number;

  code?: string;

  desc?: string;
}

export class WorkRemindRuleTypeResponse {
  type_list?: Array<WorkRemindRuleTypeDTO>;
}


export class WorkRemindListRequest extends PageReq {
  // @Remark("类型ID，不传则查全部")
  rule_type_id?: number;
  // @Remark("WAITING：待办，OFF：完成、关闭,ALL:全部")
  status_code?: string;
}

export class WorkRemindListReqsonse extends PageResp {
  remind_list?: Array<WorkRemindListDTO>;
}


export class WorkRemindOperationLogDTO {
  // @Remark("操作人")
  operation_user_name?: string;
  // @Remark("操作时间")
  operation_time?: number;
  // @Remark("操作描述")
  remark?: string;
  // @Remark("推送给了哪些人逗号分隔")
  receiver?: string;
  // @Remark("推送方式逗号分隔")
  notice_type?: string;

  operation_type?: string;

  // 附件集合
  attachment_list?: Array<string>;
}

export class WorkRemindDetailDTO {
  id?: number;
  // @Remark("标题")
  title?: string;
  // @Remark("首次发生时间")
  first_time?: number;
  // @Remark("处理总时长")
  duration?: string;
  // @Remark("状态")
  service_status?: string;
  // @Remark("待办事项类型code")
  rule_type_code?: string;
  // @Remark("待办事项类型desc")
  rule_type_desc?: string;
  // @Remark("日志集合")
  log_list?: Array<WorkRemindOperationLogDTO>;
  // @Remark("是否只读false：只读，true：非只读")
  view?: boolean;
  // 状态code
  service_status_code?: string;
  // 最晚处理期限
  deadline?: number;


  work_remind_content?: string;
}

export class WorkRemindDetailResponse extends WorkRemindDetailDTO {
  service_id?: string;
  execute_list?: Array<WorkRemindQRCodeExecuteDetailDTO>;
  repair_type?: string;
  subclass_rule_type?: string;
  execute?: boolean;
  waiting_type?: string;
}

export class WorkRemindDeviceAlertDTO {
  room_name?: string;

  room_id?: number;

  device_id?: number;

  dwell_check_type?:string;
}

export class WorkRemindDeviceDetailResponse extends WorkRemindDetailDTO {
  // @Remark("告警设备集合")
  alert_list?: Array<WorkRemindDeviceAlertDTO>;
}

export class FireSystemComponentWarnBizDTO {
  source?: string;
  component_type_num?: number;
  // 部件地址 数字
  component_addr_num?: number;
  // 部件地址 HEX
  component_addr_str?: string;
  // 1复位 2火警
  type?: number;
  // 部件说明
  desc_str?: string;

  time?: number;

  create_time?: number;

  // 回路
  fire_loop?: number;
  // 点位
  fire_point?: number;
  // 楼栋
  fire_num?: number;
  // 楼层
  fire_floor?: number;
  // 分区
  fire_partition?: number;
  // 设备类型
  fire_type_desc?: string;
  // 是否屏蔽
  need_ignore?: boolean;


  // 额外信息
  component_id?: number;

  project_id?: number;
  project_name?: string;

  room_id?: number;
  unit_id?: number;
  floor?: string;

  related_warns?: Array<FireSystemComponentWarnBizDTO>;

  last_sync_time?: number;
}

export class WorkRemindFireDetailResponse extends WorkRemindDetailDTO {
  // @Remark("消防数据")
  fire_warn_info?: FireSystemComponentWarnBizDTO;
}

export class WorkRemindLeaseExpireDetailResponse extends WorkRemindDetailResponse {
  lease_expirelog_list?: Array<WorkRemindLeaseExpireLogDTO>;
}

export class WorkRemindLeaseExpireLogDTO extends WorkRemindOperationLogDTO {
  // @Remark("签约人名称")
  subject_name?: string;
  // @Remark("合同号")
  contract_num?: string;
  // @Remark("合同到期时间")
  end_time?: number;
}

export class WorkRemindRoleExecuteDetailDTO {
  // @Remark("待办事项ID")
  work_remind_id?: number;
  // @Remark("执行人")
  execute_user_name?: string;
  // @Remark("执行时间")
  execute_time?: number;
  // @Remark("状态WAIT：待执行,WAIT_CHECK：待复查，FINISH：完成")
  service_status?: string;
}

export class WorkRemindQRCodeExecuteDetailDTO extends WorkRemindRoleExecuteDetailDTO {
  // @Remark("二维码地址")
  url?: string;
  // @Remark("位置")
  location?: string;
  // @Remark("坐标")
  coordinates?: string;
}

export class WorkRemindLogPageRequest extends PageReq {
  // @Remark("待办事项ID")
  work_remind_id?: number;

  // @Remark("排序方法：1：正序，2：倒序")
  sort_type?: number;
}

export class WorkRemindLogPageResponse extends PageResp {
  log_list?: Array<WorkRemindOperationLogDTO>;
}

export class WorkRemindLeaseExpireEmailResponse {
  lease_id?: number;

  contract_num?: string;

  room_count?: number;

  end_time?: number;

  project_name?: string;

  frozen_pwd_completed_lease?: string;

  contact_name?: string;
}

export class WorkRemindOrderInfoResponse {
  create_time?: number;
  order_id?: string;
  order_no?: string;
  order_status?: string;
  order_amount?: string;
  pay_channel?: string;
}

export class WorkRemindDisposeRequest {
  //@Remark("待办事项ID")
  work_remind_id?: number;
  //@Remark("要修改的状态")
  status ?: string;
  //@Remark("备注")
  remark?: string;
  // 附件集合
  attachment_list?: Array<string>;
}

export class RoomDirtyCleanWorkRemindRequest{
  work_remind_id?:number;
  result_type?:string;
  remark?:string;
  error_text;
}

export class WorkRemindProjectDTO {
  id?: number;
  project_name?: string;
  city_name?: string;
}

export class WorkRemindRoleDTO {
  role_id?: number;
  role_name?: string;
}

export class WorkRemindPrivateRuleListDTO {
  // @Remark("规则ID")
  id?: number;
  // @Remark("规则名")
  rule_name?: string;
  // @Remark("应用项目")
  project_list?: Array<WorkRemindProjectDTO>;
  // @Remark("执行角色")
  role_list?: Array<WorkRemindRoleDTO>;
  // @Remark("创建时间")
  create_time?: number;
  // @Remark("创建用户")
  create_user?: string;
  // @Remark("业务状态：EFFECTIVE：有效，停用：STOP")
  service_status?: string;
  // @Remark("SYSTEM:系统规则，CUSTOM：自定义")
  rule_type?: string;
}

export class WorkRemindPrivateRuleListResponse extends PageResp {
  rule_list?: Array<WorkRemindPrivateRuleListDTO>;
}

export class WeekTimeDTO {
  // @Remark("周：1-7,7为周日")
  week?: number;
  // @Remark("HH:mm")
  time?: Array<String>;
}

export class WorkTimeDTO {
  // @Remark("类型")
  work_type?: string;
  // @Remark("天，LAST为最后一天其余天数则存数值")
  day_list?: Array<String>;
  // @Remark("周数据")
  week_list?: Array<WeekTimeDTO>;
}

export class WorkRemindPrivateRuleQRCodeDTO {
  // @Remark("qrCode")
  qr_code?: string;
  // @Remark("规则ID")
  private_rule_id?: number;
  // @Remark("二维码地址")
  url?: string;
  // @Remark("位置")
  location?: string;
  // @Remark("坐标")
  coordinates?: string;
}

export class WorkRemindPrivateRuleDetailResponse {
  // @Remark("规则名")
  rule_name?: string;
  // @Remark("执行角色")
  role_list?: Array<WorkRemindRoleDTO>;
  // @Remark("待办事项名称")
  work_remind_name?: string;
  // @Remark("待办内容")
  content?: string;
  // @Remark("触发周期")
  work_time?: WorkTimeDTO;
  // @Remark("应用项目")
  project_list?: Array<WorkRemindProjectDTO>;
  // @Remark("二维码集合")
  qr_list?: Array<WorkRemindPrivateRuleQRCodeDTO>;
  // @Remark("开启复查：false否，true是")
  check?: boolean;
  // @Remark("false全公司，true指定项目")
  project_all?: boolean;
  // @Remark("推送方式：NOTIFY_TYPE_WECHAT_TEMPLATE：微信，NOTIFY_TYPE_TEXT_MESSAGE：短信，NOTIFY_TYPE_PHONE_CALL：电话")
  push_type_codes?: Array<string>;
  // @Remark("复查角色")
  check_role_list?: Array<WorkRemindRoleDTO>;
  // @Remark("查询角色")
  query_role_list?: Array<WorkRemindRoleDTO>;
  // @Remark("EFFECTIVE:有效，STOP：停用")
  service_status?: string;
  // 超时时间
  over_time_value?: number;
  // 超时时间类型
  over_time_type?: string;
}

export class WorkRemindPrivateRuleEditRequest {
  // @Remark("id为空时表示新增，有值时为修改")
  id?: number;
  // @Remark("规则名称")
  // @NotNull(message = "规则名称不能为空")
  rule_name?: string;
  // @Remark("待办事项名称")
  // @NotNull(message = "待办事项名称不能为空")
  work_remind_name?: string;
  // @Remark("时间周期")
  // @NotNull(message = "执行周期不能为空")
  work_time?: WorkTimeDTO;
  // @Remark("false全公司，true指定项目")
  // @NotNull(message = "projectAll不能为空")
  project_all?: boolean;
  // @Remark("指定项目ID，全项目时不用传")
  project_ids?: Array<number>;
  // @Remark("执行角色ID")
  // @NotNull(message = "执行角色不能为空")
  role_ids?: Array<number>;
  // @Remark("推送方式")
  // @NotNull(message = "推送方式不能为空")
  push_types?: Array<String>;
  // @Remark("待办事项内容")
  // @NotNull(message = "待办事项内容不能为空")
  content?: string;
  // @Remark("新增或修改时使用，修改时rqCode不能为空")
  qr_list?: Array<WorkRemindPrivateRuleQRCodeDTO>;
  // @Remark("开启复查：false否，true是")
  // @NotNull(message = "复查状态不能为空")
  check?: boolean;
  // @Remark("复查角色ID，修改时集合若为空则将删除已有数据")
  check_role_ids?: Array<number>;
  // @Remark("查询角色ID，修改时集合若为空则将删除已有数据")
  query_role_ids?: Array<number>;
}
